import React, { Component, createRef, useState } from 'react';
import { useScreenshot } from 'use-react-screenshot'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import alaSQLSpace from 'alasql';
import domtoimage from 'dom-to-image';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
class severe_weather_thunderstorm_and_tornado extends Component {
    constructor(props){
        super(props);
        this.state = {
		   fields: {},
		   flowchart_template: {},
		   flowchart_content: '',
		   flowchart_status: '',
		   ObjOrganization: '',
		   title:'',
		   image:null
        }
		this.Gotoorg = this.Gotoorg.bind(this);
		this.GotoHelp = this.GotoHelp.bind(this);
		this.GotoHome = this.GotoHome.bind(this);
		this.gotoBack = this.gotoBack.bind(this);
		this.GotoDoc = this.GotoDoc.bind(this);
		this.GotoProfile = this.GotoProfile.bind(this);
		this.redirectChart = this.redirectChart.bind(this);
     }
componentDidMount(){
this.getFlowChart();
this.getOrganizationList();
}
Gotoorg() {
this.props.redirectToOrg();
}
GotoHelp() {
this.props.redirectToHelp();
}
gotoBack() {
	this.props.closeFlowChartView();
	}
GotoHome() {
this.props.redirectToCollection();
}
GotoDoc() {
this.props.redirectToDoc();
}
GotoProfile() {
	this.props.redirectToProfile();
}
logout() {
	  confirmAlert({
      title: 'Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => localStorage.clear(window.location.href = '/')
        },
        {
          label: 'No'
        }
      ]
    });
	}
	getOrganizationList()
     {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
      }
redirectChart(e){
  e.preventDefault();
  document.getElementById('chart_application').parentNode.style.overflow = 'visible';
  const input = document.getElementById('chart_application');
	input.style.top = window.innerHeight + 'px';
        html2canvas(input, {
        logging: true, letterRendering: 1, allowTaint: false, useCORS: true, dpi: 200, height: 1000})
      .then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
        input.style.top = 0;
		/*const u8Image  = this.b64ToUint8Array(imgData);*/
		const imgageData = imgData.replace(/^data:image\/(png|jpg);base64,/, "");
		/*const output = encodeURIComponent(imgData);*/
		/*const newData = imgData.replace(/^data:image\/png/, "data:application/octet-stream");*/
		const chartid = localStorage.getItem("chartid");
		
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let res = alaSQLSpace('SELECT * FROM ? where id = ?', [charts, chartid]);
		let chart_name = res[0].title;
		chart_name = chart_name.toLowerCase();
		chart_name = chart_name.replace(/:\s*/g, " ");
		chart_name=chart_name.replace(/\//g,"");
		chart_name=chart_name.replace(/\s+/g,"_");
		/*canvas.setAttribute('width',900);
        canvas.setAttribute('height',1000);*/
		var link = document.createElement("a");
        /*link.download = 'chart_'+chartid+'.png';*/
		link.download = 'chart_'+chart_name+'.png';
        link.href = imgData;
        link.click();
	
		
        /*const data = new FormData();
		data.append("chartid", chartid);
		data.append("data", imgageData);
		var xhr = new XMLHttpRequest();
        xhr.open( 'post', 'http://3.89.110.194/pdf.php', true );
		xhr.send(data);*/
        /*window.location.reload();*/
      });
  }	  
getFlowChart()
{ 
	const collectionid = this.props.collectionid;
	const chartid = this.props.chartid;
	const organizationid = this.props.organizationid;
let flowchart_template = this.state.flowchart_template;
flowchart_template["action"]="thunderstorm_tornado";
flowchart_template["collectionid"]=collectionid;
flowchart_template["chartid"]=chartid;
flowchart_template["organizationid"]=organizationid;
fetch(global.flowchart_api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.flowchart_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
	 const html=response.html;
	 const title=response.title;
    if(status === "true")
    {
	   this.setState({
		  flowchart_content: html,
		  title: title,
		  flowchart_status: status
      })
    }       
});
}
 render() {
	    let token = localStorage.getItem("session");
		let token_array=JSON.parse(token);
		let user_level_2 = token_array.admin;
		let user_level_1 = token_array.organization_admin;
		let user_super_admin = token_array.super_admin;
		let hide_profile = token_array.hide_profile;
		let hide_logout = token_array.hide_logout;
		const flowstatus = this.state.flowchart_status;
		let flowcontent;
		if(flowstatus=='true')
		{
		flowcontent = <ul class="cards">{ReactHtmlParser(this.state.flowchart_content)}<div class="nub-lines lines-top" style={{'position': 'absolute', 'left': '5.75%', 'top': '27.2%', 'width': '4px', 'height': '28.3594px'}}></div>
<div class="nub-lines lines-top" style={{'position': 'absolute', 'left': '5.75%', 'top': '43%', 'width': '4px', 'height': '28px'}}></div>
<div class="nub-lines lines-right" style={{'position': 'absolute', 'left': '12.3%', 'top': '5.2%', 'width': '4px', 'height': '585.922px'}}><div class="rightarrow"></div> </div>
<div class="nub-lines lines-right-top" style={{'position': 'absolute', 'left': '12.3%', 'top': '158px', 'width': '4px', 'height': '488px'}}><div class="rightarrow"></div> </div>
<div class="nub-lines lines-right-top" style={{'position': 'absolute', 'left': '12.3%', 'top': '257px', 'width': '4px', 'height': '388px'}}><div class="rightarrow"></div> </div>
<div class="nub-lines lines-right" style={{'position': 'absolute', 'left': '24.5%', 'top': '48px', 'width': '4px', 'height': '17px'}}><div class="rightarrow"></div> </div>
<div class="nub-lines lines-left" style={{'position': 'absolute', 'left': '24.65%', 'top': '157px', 'width': '4px', 'height': '42.2%'}}><div class="rightbottomarrow"></div></div>
<div class="nub-lines lines-top" style={{'position': 'absolute', 'left': '19%', 'top': '292px', 'width': '4px', 'height': '27px'}}></div>
<div class="nub-lines" style={{'position': 'absolute', 'left': '18.5%', 'top': '46%', 'width': '4px', 'height': '21px'}}><div class="straight-line-left">
<div class="down-lines-left lines-top"></div></div><div class="straight-line-right"><div class="down-lines-right lines-top"></div></div></div>
<div class="nub-lines lines-right" style={{'position': 'absolute', 'left': '24.65%', 'top': '55.25%', 'width': '4px', 'height': '16px'}}><div class="rightarrow"></div> </div>
<div class="nub-lines" style={{'position': 'absolute', 'left': '16%', 'top': '52%', 'width': '4px', 'height': '24px'}}><div class="straight-line-right">
<div class="down-lines-right lines-top"></div></div></div>
<div class="nub-lines" style={{'position': 'absolute', 'left': '380px', 'top': '638.781px', 'width': '4px', 'height': '6.8125px'}}></div>
<div class="nub-lines lines-right" style={{'position': 'absolute', 'left': '37.2%', 'top': '48px', 'width': '4px', 'height': '148.703px'}}>
<div class="rightarrow"></div></div>
<div class="nub-lines" style={{'position': 'absolute', 'left': '28.5%', 'top': '45%', 'width': '4px', 'height': '18px'}}><div class="straight-line-right" style={{'top':'0px'}}>
<div class="down-lines-right lines-bottom" style={{'bottom':'0px', 'top':'auto'}}></div></div></div>
<div class="nub-lines" style={{'position': 'absolute', 'left': '31%', 'top': '52.6%', 'width': '4px', 'height': '18px'}}>
<div class="straight-line-left" style={{'top':'0px'}}>
<div class="down-lines-left lines-bottom" style={{'bottom':'0px', 'top':'auto'}}></div></div>
<div class="straight-line-right" style={{'top':'0px'}}>
<div class="down-lines-right lines-bottom" style={{'bottom':'0px', 'top':'auto'}}></div></div></div>
<div class="nub-lines lines-left" style={{'position': 'absolute', 'left': '37%', 'top': '48%', 'width': '4px', 'height': '19.5%'}}><div class="rightbottomarrow"></div></div>
<div class="nub-lines lines-top" style={{'position': 'absolute', 'left': '43.5%', 'top': '307.094px', 'width': '4px', 'height': '28px'}}></div>
<div class="nub-lines lines-right" style={{'position': 'absolute', 'left': '49.8%', 'top': '48px', 'width': '4px', 'height': '425.266px'}}><div class="rightarrow"></div> </div></ul>;
		}else{
		flowcontent = '';
		}
 return (
    <div id="ember361" class="ember-view ember-app">
	<div className="menu">
	<ul>
		<li className="homeicon"><a onClick={this.GotoHome}>h</a></li>
		<li className="back"><a onClick={this.gotoBack}>M</a></li>
		<li className="titlebar">{this.state.ObjOrganization}</li>
		{hide_logout==0?<li className="logout"><a onClick={this.logout}>o<span className="text">Logout</span></a></li>:''}
		{hide_profile==0?<li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>:''}
		<li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
		<li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
		{user_level_1==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
	</ul>
</div>
<div id="application">
  <div class="overlay-container" id="chart_application">
  <div class="overview-controls">
    <h2 class="overview-title">{this.state.title}</h2>
    <a class="close" data-ember-action="1447" onClick={this.gotoBack}>x</a>
  </div>
  <div class="overview-scroller">
	<div id="ember3414" class="ember-view overview">
      {flowcontent}
	</div>
  </div>
  <div class="clear"></div>
</div>
</div>
</div>
);
}
}
export default severe_weather_thunderstorm_and_tornado;