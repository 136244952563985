import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import axios from 'axios';
import importcsv from '../../../download/sample_import.csv';
import Papa from 'papaparse';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import ReactExport from "react-export-excel";
import Popup from '../../../components/ImportPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ImportCSV extends Component {
        constructor(props){
        super(props);
        this.state = {
        name: '',
        ObjOrganizations: [],
		csvfile: undefined,
        fields: {},
		delete_import_table:{},
		upload_count:{},
		import_fields: {},
        organization_template: {},
		import_error: [],
		loader_class: '',
		total_import_record: 0,
		progressbar_component:false,
		count_second:0,
		import_error_count: 0,
		userImportCount:0,
		file_attachment_attachment: null,
		file_attachment_file_folder_id: '',
        folder_template: {},
        error: {},
		import_template: {},
		dataSet1:[],
		ObjImportError: [],
        selectedFile: null,
		showPopup: false,
        pathname:null,
		CurrentDateTime:''
        }
        this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.updateData = this.updateData.bind(this);
		this.redirectToImportCSV = this.redirectToImportCSV.bind(this);
		this.redirectUser=this.redirectUser.bind(this);
		this.editProfile=this.editProfile.bind(this);
		this.forceImportCSV=this.forceImportCSV.bind(this);
     }
     
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 redirectUser(){
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
      this.props.history.push('/users/User?organization='+ token); 
    }
	 editProfile(user_id,organizationid)
     {
     this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
	 redirectToImportCSV()
	 {
	  const query = new URLSearchParams(this.props.location.search);
      const organization_id = query.get('organization');	 
	  this.props.history.push('/ImportCSV?organization='+organization_id);
	  window.location.reload();
	 }
	 handleChange = event => {
    this.setState({
      csvfile: event.target.files[0]
    });
  };
  
importCSV(e){	  
	 e.preventDefault();
	  this.setState({
      loader_class: 'loader',
	  progressbar_component:true
      })
	  
	 const query = new URLSearchParams(this.props.location.search);
     const organization_id = query.get('organization');
	 
	  let delete_import_table = this.state.delete_import_table;
	  delete_import_table["action"]="deleteUserImport";
	  delete_import_table["organization"]=organization_id;
	  
	  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.delete_import_table)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
       if(status === "true")
       {
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
	  skipEmptyLines: true
    });
	    }       
 });
  };


  
updateData(result) {
	let userImportCount = this.state.userImportCount;
    var data = result.data;
	this.setState({
		   total_import_record: data.length
		   }) 
	const query = new URLSearchParams(this.props.location.search);
    const organization_id = query.get('organization');
	let fields = this.state.fields;
	fields["action"] = "csv_import";
	fields["organization_id"] = organization_id;
	fields["user_records"] = data;
	this.createFileApi();
    }
redirectOrganization(){
this.props.history.push('/Organization');
}
forceImportCSV()
{
	  this.setState({
      loader_class: 'loader',
	  progressbar_component:true
      })	
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  
	  
	var data={
                "action": "forceImportCSV",
                "organizationid":organizationid
                }
	fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((response) => {
           const status=response.status;
           if(status === "true")
           {
			  this.setState({
			  loader_class: '',
			  progressbar_component:false
			  }) 
			  this.props.history.push('/users/User?organization='+organizationid+'&action=import');
           }
      });
	  
	  
	}
getUploadCount(organization_id)
{     let total_import_record = this.state.total_import_record;
	  let upload_count = this.state.upload_count;
	  upload_count["action"]="uploadCount";
	  upload_count["organization"]=organization_id;
	  console.log(JSON.stringify(this.state.upload_count));
	  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.upload_count)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		const count=response.count;
       if(status === "true")
       {
		   var perc = (count/total_import_record)*100;
		   perc = Math.round( perc );
		   this.setState({
		   userImportCount: perc
		   }) 
		   
		   }       
 });
	
	
	
}
createFileApi()
{
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  
	  var arr = [];
	  
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      //.then((response) => response.json())
	  .then((response) => {
		  if (response.ok) {
			return response.json();
		  }
		  throw new Error('Something went wrong');
		})
      .then((response) => {
           const status=response.status;
		   const code=response.code;
		   const error_list=response.error_list;
		   const error_list_count=response.error_count;
		   
           if(status === "true" && code === "200")
           {
			  this.setState({
              loader_class: '',
			  progressbar_component:false
              })
			  toast.success('user imported successfully!', {
				  position: toast.POSITION.TOP_CENTER,
				  autoClose: 4000
				});
			  setTimeout(() => {
			  this.props.history.push('/users/User?organization='+token+'&action=import');
			   },4000);
           }else if(status === "false" && code === "400"){
			   this.setState({
			  loader_class: '',
			  progressbar_component:false,
              import_error: error_list,
			  import_error_count : error_list_count,
			 
              })
			   this.getUserImportErrorList();
           }else{
			   this.setState({
              loader_class: '',
			  progressbar_component:false
              }) 
			  
			    toast.error(response.message, {
      position: toast.POSITION.TOP_CENTER
    });
		   }
      }).catch((error) => {
  console.log(error)
});
    }
getUserImportErrorList()
{
	  let import_template = this.state.import_template;
	  let ObjImportError = this.state.ObjImportError;
	  import_template["action"]="getUserImportErrorList";
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  import_template["organizationid"]=organizationid;
	  
	  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.import_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		    const error_list=response.error_list;
       if(status === "true")
       {
        this.setState({
          dataSet1: error_list
      })
       }       
 });
  }	
getCurrentDateTime()
{
let date = new Date().getDate(); //Current Date
		let year = new Date().getFullYear(); //Current Year
		let hours = new Date().getHours();
		hours = hours % 12;
        hours = hours ? hours : 12;
		let minute = new Date().getMinutes();
		minute = minute < 10 ? '0'+minute : minute;
		let second = new Date().getSeconds();
		let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        let now = new Date();
        let thisMonth = months[now.getMonth()];
		let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
		let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
		let current_datetime = dayName +'. '+ thisMonth +' '+ date +', '+ year +' '+ hours +':'+minute+':'+second+ ' '+getCurrentAmPm;
		this.setState({
	    CurrentDateTime: current_datetime
        })
		
}
GetOrganizationDetail(){
let organization_template = this.state.organization_template;
let ObjOrganizations = this.state.ObjOrganizations;
organization_template["action"]="getOrganizationDetails"; 
let session = localStorage.getItem("session");
let session_array=JSON.parse(session);
let organizationid = session_array.organization_id;
organization_template["organizationid"]=organizationid; 
fetch(global.api_url,
 {
	 method: 'POST',
	 headers: {
		 Accept: 'application/json',
		 'Content-Type': 'application/json'
	 },
	 body: JSON.stringify(this.state.organization_template)
 })
  .then((response) => response.json())
  .then((response) => { 
	const status=response.status;
	const name = response.name;
   if(status === "true")
   {

	this.setState({
	  ObjOrganizations: response.name
  })
   }       
});

}
logout() {
if (window.confirm("Are you sure you want to log out?")) {
localStorage.clear();
window.location.href = '/';
}
}

togglePopup() {
   this.setState({
     showPopup: !this.state.showPopup
   });
 }
componentDidMount() {
this.GetOrganizationDetail();
this.getCurrentDateTime();
const query = new URLSearchParams(this.props.location.search);
const organization_id = query.get('organization');
 setInterval(() => {
	  if(this.state.progressbar_component==true)
	  {
      this.getUploadCount(organization_id);
	  }
	  
    }, 2000);


}
    render() {
		if (localStorage.getItem('session')) {
		let progressbar_component = this.state.progressbar_component;
		let userImportCount = this.state.userImportCount;
	 let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
	let errorList = this.state.import_error.map(v => (
	  <p>{v.error}</p>
    ));
    return (
    <div className="bodyscroll">
	<ToastContainer closeButton={false} /> 
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div  className="navbar-collapse collapse">
        <ul  className="nav navbar-nav navbar-left">
          <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul  className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
            <li className=""><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
            <li><a onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
          <a className="" onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectUser} data-original-title="">Users</a>
          </div>
        </div>
          <div className="row">
          <div className="col-xs-12">
          <h2>Import User - CSV File</h2>
		  <p>Download sample csv file <a href={importcsv}>Click here</a></p>
		  <span className="shortText">(If you are using Safari as your web browser, RIGHT click on the link above and click 'Download Linked File')</span>
		  {errorList}
		  {this.state.import_error_count>0?
		  <div>
		  <p>
       
		  Download Log file : <ExcelFile element={<a>Click Here</a>}>
                <ExcelSheet data={this.state.dataSet1} name="Error Log">
                    <ExcelColumn label="First Name" value="first_name"/>
                    <ExcelColumn label="Last Name" value="last_name"/>
                    <ExcelColumn label="Email Address" value="email"/>
                    <ExcelColumn label="Group" value="groups"/>
                    <ExcelColumn label="Location" value="location"/>
                    <ExcelColumn label="Password" value="password"/>
                    <ExcelColumn label="User Level 1" value="user_level_1"/>
                    <ExcelColumn label="User Level 2" value="user_level_2"/>
					          <ExcelColumn label="Status" value={(col) => col.status ==1 ? "Active" : "De-Active"} />
                </ExcelSheet>
				</ExcelFile>
				&nbsp; &nbsp; 
		  Email Log file : <a onClick={this.togglePopup.bind(this)}>Click Here</a>
		  
		  {this.state.showPopup ?
			 <Popup
			  text='Click "Close Button" to hide popup'
			  closePopup={this.togglePopup.bind(this)}
			 />
         : null
       }
		  
         </p>
		 <br/>
		 
		 <button className="btn btn-success" onClick={this.forceImportCSV}>Keep error and proceed</button> &nbsp;
		 <a className="btn btn-success" onClick={this.redirectToImportCSV}>Do you want to correct error and proceed</a>
		 </div>
		  :
		  <div className="csvForm">
		  <form 
		  acceptCharset="UTF-8"
		  onSubmit={this.importCSV.bind(this)}
		  className="csv_import"
		  id="csv_import">
		  <div className="form-group">
	      <input
          className="csv-input"
          type="file"
          ref={input => {
            this.filesInput = input;
          }}
          name="file"
		  required="required"
          placeholder={null}
          onChange={this.handleChange}
          />
         </div>
		 <div className="form-group">
		 {progressbar_component?null:<button className="btn btn-success">Import CSV</button>}&nbsp;
		 {progressbar_component?null:<a className="btn btn-default" onClick={this.redirectUser}>Cancel</a>}
		
		 <div className="progress_class">{progressbar_component?<ProgressBar striped variant="success" now={userImportCount} label={`${userImportCount}%`} />:null}</div>
		 </div>
		 </form>
		 </div>
		  }
          </div>
        </div>
      </div>
    </div>
    );
		}else{
			window.location.href = '/';
		}
  }
}
export default ImportCSV;