import React, { Component } from 'react';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import FileViewer from 'react-file-viewer';
import Base64 from 'base-64';
class DocView extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:null,
            password:null,
            newpassword:null,
            confirmpassword:null,
			ObjCollection : [],
            userid:null,
            organizationid:null,
			filetype : ''
        }
        this.GotoHome=this.GotoHome.bind(this);
     }
	 
	 getOrganizationList()
     {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
      }

     componentWillMount(){
        //this.getOrganizationList();
		let filetype = this.props.filetype;
		console.log(filetype);
        let sessiondata= localStorage.getItem("session");
        let sdata=JSON.parse(sessiondata);

        this.setState({
			ObjOrganization: sdata.organization,		  
            userid: sdata.id,
            organizationid:sdata.organization_id,
            email:sdata.email,
			filetype : filetype
        })
     }

     GotoHome(){
		let filefolderid = this.props.filefolderid;
		let filesubfolderid = this.props.filesubfolderid;
		//console.log(filefolderid);
		//console.log(filesubfolderid);
		if(filefolderid!='' && filesubfolderid!='')
		{
		this.props.closeFileSubFolderView(filefolderid,filesubfolderid);	
		}else if(filefolderid!='' && filesubfolderid==''){
		this.props.closeFlowChartView();
		}else{
		this.props.closeFileView();	
		}
      }
     
     render(){
		let filetype = this.state.filetype;
		console.log(filetype);
		let filepath = Base64.decode(this.props.filepath);
		let filefolderid = this.props.filefolderid;
		let filesubfolderid = this.props.filesubfolderid;
		 const docs = [
			{ 
			  uri: filepath
			}
		  ];
         return(
            <div  className="ember-view ember-app scrollPage">
            <div className="menu">
                <ul>
                  <li onClick= {this.GotoHome}><a><img className="orgLogo" src="/assets/images/back.png" width="32px" /></a></li>
                </ul>
              </div>
			  {filetype=='pdf' || filetype=='PDF' || filetype=='png' || filetype=='PNG' || filetype=='jpeg' || filetype=='JPEG' || filetype=='jpg' || filetype=='JPG' || filetype=='gif' || filetype=='GIF' ?
              <div className="docView">
			   <DocViewer
			   documents={docs}
			   config={{
				  header: {
					disableHeader: false,
					disableFileName: false,
					retainURLParams: false
				  }
                 }}
			   pluginRenderers={DocViewerRenderers} />
              </div>
			 : 
			 <div>
			 <FileViewer
                 fileType={filetype}
                 filePath={filepath} /> 
			  </div>
				 }
			 
			 
              
              </div>
         );
     }
    }
    export default DocView;