import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import $ from 'jquery';
class AddFile extends Component {
    constructor(props){
        super(props);
		
        this.state = {
        name: '',
		selectedFiles: [],
        ObjOrganizations: [],
		progressInfos: [],
        ObjFolder: [],
		ObjSubFolder: [],
		allowProgress: false,
        fields: {},
		progressMax: 0,
		progressbar:false,
		progressbar_width:'20%',
        organization_template: {},
		file_attachment_attachment: null,
		file_attachment_file_folder_id: '',
		file_attachment_sub_folder_id:'',
        folder_template: {},
		sub_folder_template: {},
        error: {},
		multiUploaderSetArray:[],
		loader_class: '',
        selectedFile: undefined,
        pathname:null,
		files: []
        }
        this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.redirectAttachment=this.redirectAttachment.bind(this);
		this.onDrop = this.onDrop.bind(this);
     }

	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 redirectAttachment()
	  {
		  const query = new URLSearchParams(this.props.location.search);
          const organizationid = query.get('organization');
		  this.props.history.push('/Attachment?organization='+organizationid);
	  }
     handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  //let file_attachment_cache_on_app_isChecked = document.getElementById('file_attachment_cache_on_app').checked;
	  //let selected_folder = document.getElementById("file_attachment_file_folder_id");
	  //fields["file_attachment_file_folder_id"]= selected_folder;
	  fields["file_attachment_cache_on_app"] = 'false';
	  
	  if (this.refs.file_folderid) {
       fields["file_attachment_file_folder_id"]=this.refs.file_folderid.value;
      }
	  if (this.refs.file_subfolderid) {
       fields["file_attachment_sub_folder_id"]=this.refs.file_subfolderid.value;
      }
	  
	  if(this.refs.file_folderid.value=='')
	  {
	   fields["file_attachment_file_folder_id"] = 'false'
	  }else{
		  fields["file_attachment_file_folder_id"]=this.refs.file_folderid.value;
	  }
	  
	  if(this.refs.file_subfolderid.value=='')
	  {
	   fields["file_attachment_sub_folder_id"] = 'false'
	  }else{
		  fields["file_attachment_sub_folder_id"]=this.refs.file_subfolderid.value;
	  }
 
     fields["action"]="createFile";
     fields["organizationid"]=token;
    this.setState({errors: errors});
     return formIsValid;
    }
	 handleChangeSubFolderName(field, e)
	{
		let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
	}
    handleChangeName(field, e){
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  let sub_folder_template = this.state.sub_folder_template;
	  let ObjSubFolder = this.state.ObjSubFolder;
	  sub_folder_template["action"]="detailSubFolder"; 
	  sub_folder_template["folderid"]=e.target.value;
	  sub_folder_template["organizationid"]=organizationid;
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.sub_folder_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const subfolder_details = response.subfolder_details;
			
           if(status === "true")
           {
            this.setState({
              ObjSubFolder: response.subfolder_details
          })
           }else{
			   this.setState({
              ObjSubFolder: []
          })
		   }
     });
	  
    }
    redirectOrganization(){
      this.props.history.push('/Organization');
      }
    GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {

            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }
    GetFolderList(){
      let folder_template = this.state.folder_template;
      let ObjFolder = this.state.ObjFolder;
      folder_template["action"]="getAddedFolderList";
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  let session= localStorage.getItem("session");
	  let session_array=JSON.parse(session);
	  let userid = session_array.id;
	  let groupid = session_array.group_id;
	  
      folder_template["organizationid"]=organizationid;
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.folder_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const folder_details = response.list;
           if(status === "true")
           {
            this.setState({
              ObjFolder: folder_details
          })

           }       
     });
     
    }
	
	onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
    }
  }
  
    notify = async (e) => {
	const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');  
    toast.success("File(s) uploaded successfully!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      hideProgressBar: true
    });

	setTimeout(function() {
      this.props.history.push('/Attachment?success=true&organization='+ token);
  }.bind(this), 2000)
	
  }
  
    createFileApi()
    {
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
		   const code=response.code;
           if(status === "true")
           {
			  this.notify();
			  this.setState({
              loader_class: '',
			  progressbar:false
              }) 
           }
           else{
			   this.setState({
              loader_class: ''
              })
              alert("Something went wrong, please try again");
           }
      });
    }
    logout() {
if (window.confirm("Are you sure you want to log out?")) {
localStorage.clear();
window.location.href = '/';
}}
   frame(elem,width,id,i) {
      if (width >= 100) {
        clearInterval(id);
        i = 0;
      } else {
        width++;
		this.setState({
        progressbar_width: width + "%",
       })
		elem.innerHTML = width + "%";
      }
    }
	
   move(i) {
  if (i == 0) {
    i = 1;
    var elem = document.getElementById("progressbar");
    var width = 10;
    var id = setInterval(this.frame(elem,width,id,i), 10);
  }}
   moveProgress(interval_time) {
  var progress = document.getElementById("progressbar");	   
  var a = setInterval(function() {
    progress.value = progress.value + 1;

    if (progress.value == 100) {
      clearInterval(a);
    }
  }, interval_time);}
  
   fileSubmit = async (e) => {
    e.preventDefault();
    if(this.handleValidation()){
	let selectedFiles = this.state.selectedFiles;	
	console.log(selectedFiles);
	let file_count = selectedFiles.length;
	let _progressInfos = [];
	let file_size_array = [];
	$(".progress-div").show();
	this.setState({
      loader_class: '',
	  progressbar:true
      })

	for (let l = 0; l < selectedFiles.length; l++)
	{
		file_size_array.push(selectedFiles[l].size);
	}
	 
	var file_size_total = 0;
	for (var z = 0; z < file_size_array.length; z++) {
		file_size_total += file_size_array[z] << 0;
	} 
  console.log(file_size_total);

  if(file_size_total<=15000000)
  {
    let multiUploaderSetList = [];
	  let file_field_details = [];
   for (let i = 0; i < selectedFiles.length; i++)
	{
    let data = new FormData();
	let selectedFileName = selectedFiles[i].name;
	//selectedFileName = selectedFileName.replace(/,/g,'.').replace(/[.](?!\d*$)/g,'');
	_progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    data.append('file_attachment_attachment', selectedFiles[i])
    const uploadres = await this.multiUploaderSet(data);
	multiUploaderSetList.push(uploadres.data);
    }
	this.setState({
      multiUploaderSetArray: multiUploaderSetList,
	  progressInfos: _progressInfos
      })
	let fields = this.state.fields;
	fields["file_array"] = this.state.multiUploaderSetArray;
	console.log(this.state.fields);
	let file_array_count = this.state.multiUploaderSetArray.length;
	if(file_array_count>0)
	{
	this.setState({
      allowProgress: true
      })
	let file_actual_size = parseInt(file_size_total) / 1024;
	let file_interval_time = Math.round((file_actual_size / file_count) * 1);	
	this.moveProgress(parseInt(file_interval_time));	
	this.createFileApi();	
	}else{
	alert("Select at least one file!");	
	}

  }else{
    toast.warning("File size limit is upto 15 MB!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      hideProgressBar: true
    });
  }

	
     


}else{
	this.setState({
      loader_class: ''
      })	 
    alert("Mandatory Field Emplty!");
     }

     
    }



   multiUploaderSet = async (data) => {
   const res = await axios.post("https://expressapi.safeguardmerp.com/uploadimage", data, {
   })
   return res;


  };

  uploadFiles(field, e) {
		this.setState({
        selectedFile: e.target.files[0],
        loaded: 0,
      })

   console.log(this.state.selectedFile);
   let fields = this.state.fields;
   switch (e.target.name) {
   case 'file_attachment_attachment':
   if(e.target.files.length > 0) {
   this.setState({ fileName: e.target.files[0].name });
   fields[field] = e.target.files[0].name;
   this.setState({fields});
   }
   break;
   default:
   this.setState({ [e.target.name]: e.target.value });
   } 
   }
  componentDidMount() {
	  $(".progress-div").hide();
    this.GetOrganizationDetail();
    this.GetFolderList();
  }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
  render() {
  let allowProgress = this.state.allowProgress;	  
  const now = 70;
  const progressInstance = <ProgressBar now={now} label={`${now}%`} />;
  let progressbar_width = this.state.progressbar_width;
  const myBarStyle = {
      width: progressbar_width,
      height: "30px",
      backgroundColor: "green",
      textAlign: "center",
	  lineHeight: "30px",
	  color: "white"
    };
  const { selectedFiles, progressInfos} = this.state;
  //console.log(selectedFiles);
  let progressbar = this.state.progressbar;
  const files_details = selectedFiles.map(file => (
      <li key={file.name}>
        {file.name} - {this.formatBytes(file.size)}
      </li>
    ));
	let folderList = this.state.ObjFolder.map(v => (
	 <option value={v.id}>{v.name}</option>
    ));
	let subFolderList = this.state.ObjSubFolder.map(v => (
	 <option value={v.id}>{v.name}</option>
    ));
	if (localStorage.getItem('session')) {
    return (
        <div className="bodyscroll">
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span  className="sr-only">Toggle navigation</span>
          <span  className="icon-bar"></span>
          <span  className="icon-bar"></span>
          <span  className="icon-bar"></span>
        </button>
      </div>
      <div  className="navbar-collapse collapse">
        <ul  className="nav navbar-nav navbar-left">
          <li><a  className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul  className="nav navbar-nav navbar-right">
              <li  className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
            <li><a>Edit Profile</a></li>
            <li><a onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
          <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » <a onClick={this.redirectAttachment}>Supporting Files</a>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>Create Supporting File</h2>
          <form acceptCharset="UTF-8"
          onSubmit= {this.fileSubmit.bind(this)}
          className="new_file_folder"
          id="new_file_folder">
     <div>
	<input name="utf8" type="hidden" value="✓"/>
	</div>
      <div className="form-group">
      <div className="file-container">
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section className="container fileDrop">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {selectedFiles &&
                  Array.isArray(selectedFiles) &&
                  selectedFiles.length ? (
                    <div className="selected-file">
                      {selectedFiles.length > 3
                        ? `${selectedFiles.length} files`
                        : selectedFiles.map((file) => file.name).join(", ")}
                    </div>
                  ) : (
                    `Drag and drop files here, or click to select files`
                  )}
                </div>
                <aside className="selected-file-wrapper">
				 <h4>Files</h4>
				 <ul>{files_details}</ul>
                </aside>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
	  {allowProgress?<div className="form-group fullWidth progress-div">
      <progress id="progressbar" className="progress is-large is-info" max="100" value="10"></progress>
      </div>:null}
	  <div><ToastContainer/></div>
      <div className="form-group">
      <label htmlFor="file_attachment_file_folder_id">Select Folder</label>&nbsp;
      <select
	  id="file_attachment_file_folder_id"
	  name="file_attachment_file_folder_id"
	  ref="file_folderid"
	  className="form-control"
	  onChange={this.handleChangeName.bind(this, "file_attachment_file_folder_id")}
	  >
	  <option value="">None</option>
	  {folderList}
	  </select>
	  &nbsp;
	  <label htmlFor="file_attachment_file_folder_id">Select Subfolder</label>&nbsp;
	  <select
	  id="file_attachment_sub_folder_id"
	  name="file_attachment_sub_folder_id"
	  ref="file_subfolderid"
	  className="form-control"
	  onChange={this.handleChangeSubFolderName.bind(this, "file_attachment_sub_folder_id")}
	  >
	  <option value="">None</option>
	  {subFolderList}
	  </select>
    </div>
    
    <div className="form-group">
      <input className="btn btn-success" name="commit" type="submit" value="Upload" />&nbsp;
	  <a className="btn btn-default" onClick={this.redirectAttachment}>Cancel</a>
	  <div className={this.state.loader_class}></div>
    </div>
    
  </form>
          </div>
        </div>
      </div>
    </div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default AddFile;