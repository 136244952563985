import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization';
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import DocView from './DocView';
import PrintPDF from './PrintPDF';
import biological_attack_incident from './Charts/biological_attack_incident';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import $ from 'jquery';
class Collection extends Component {
    constructor(props){
        super(props);
        this.state = {
		   storage_template: {},
		   update_template: {},
		   ObjStorage: [],
		   ObjCollection: [],
		   ObjOrganization:'',
		   isLoggedIn: false,
		   messages_template : {},
		   ObjMessages : [],
		   loader: 'spinner',
		   filepath: '',
		   render: false
        }
    this.Gotoorg=this.Gotoorg.bind(this);
	this.GotoHelp=this.GotoHelp.bind(this);
    this.GotoDoc=this.GotoDoc.bind(this);
    this.GotoProfile=this.GotoProfile.bind(this);
	this.GotoSendMessage=this.GotoSendMessage.bind(this);
	this.GotoEmergency=this.GotoEmergency.bind(this);
	this.GotoShooter=this.GotoShooter.bind(this);
	this.GotoViewMessage=this.GotoViewMessage.bind(this);
	this.redirectChart=this.redirectChart.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.logout=this.logout.bind(this);
	this.GotoDocView=this.GotoDocView.bind(this);
     }

    redirectDashboard(){
		this.props.history.push('/Collection');
	}
    Gotoorg(){
        this.props.history.push('/Organization');
        //alert("click");
    }
	GotoHelp(){
		this.props.history.push('/help');
	}
	
	GotoDoc(){
		this.props.history.push('/Doc');
    }
	
	redirectChart(collectionid){
		this.props.history.push('/Charts?collectionid='+collectionid);
		localStorage.setItem('collectionid', '');
		localStorage.setItem('collectionid', collectionid);
    }

  GotoProfile(){
    this.props.history.push('/Profile');
  }
  GotoDocView()
  {
	  this.props.history.push('/DocView');
  }
 
 GotoSendMessage()
  {
	  this.props.history.push('compose');
  }
  
  GotoEmergency()
  {
	  this.props.history.push('emergency');
  }
  
  GotoShooter()
  {
	  this.props.history.push('shooter');
  }
  
  GotoViewMessage()
  {
	this.props.history.push('messages');
	 
  }

    logout() {
    confirmAlert({
      title: 'Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => localStorage.clear(window.location.href = '/')
        },
        {
          label: 'No'
        }
      ]
    });
  }
  clearLocalStorage()
	  {
		localStorage.clear();
		window.location.href = '/';
	  }
  componentWillMount() {
  const query = new URLSearchParams(this.props.location.search);
  const status = query.get('status');
  if(status!='' && status!=null)
  {
	   if(window.navigator.onLine==true)
		  {
			  this.updateLocalStorage();
		  }
  }else{
	  if (localStorage.getItem('session')) {
		  if(window.navigator.onLine==false)
		  { 
		  }else{
			   let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
	  let update_template = this.state.update_template;
	  update_template["action"]="getMERPUpdateStatus";
	  update_template["organizationid"]=organizationid;
	  
	  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.update_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		const update_status=response.update_status;
       if(status === "true")
       {
		   if(update_status==0)
		   {
		    this.getCollectionList();
            this.getOrganizationList();
		    this.getMessageList();
			let organization_token = localStorage.getItem("organization_details");
	        let organization_token_array=JSON.parse(organization_token);
	        let user_logo = organization_token_array.filepath;
		    this.setState({
            filepath: user_logo
            })
		   }else{
			   this.updateLocalStorage();
		   }
		   }else{
			this.getCollectionList();
            this.getOrganizationList();
		    this.getMessageList();
			let organization_token = localStorage.getItem("organization_details");
	        let organization_token_array=JSON.parse(organization_token);
	        let user_logo = organization_token_array.filepath;
		    this.setState({
            filepath: user_logo
            })
	   }
 });
		  }
	  }else{
		   window.location.href = '/';
		  }
  }
  
  localStorage.setItem('drill_log', '');
  }
  updateLocalStorage()
  {
  if (localStorage.getItem('session')) {
  let storage_template = this.state.storage_template;
  let ObjStorage = this.state.ObjStorage;
  storage_template["action"]="getLocalStorageUpdate";
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  let userid = session_array.id;
  let groupid = session_array.group_id;
  storage_template["organizationid"]=organizationid;
  storage_template["userid"]=userid;
  storage_template["groupid"]=groupid;
  
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.storage_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const details = response.details;
		const organization_details = response.organization_details;
       if(status === "true")
       {
		localStorage.setItem('session', JSON.stringify(response.user_details));   
		localStorage.setItem('organization_details', '');   
		localStorage.setItem('safeguard', '');
		localStorage.setItem('organization_details', JSON.stringify(response.organization_details));
        localStorage.setItem('safeguard', JSON.stringify(response.details));
		//localStorage.setItem('safeguard_messages', JSON.stringify(response.messagelist));
		this.getCollectionList();
        this.getOrganizationList();
		this.getMessageList();
		
		let organization_token = localStorage.getItem("organization_details");
	    let organization_token_array=JSON.parse(organization_token);
	    let user_logo = organization_token_array.filepath;
		this.setState({
          filepath: user_logo
        })
		
       }       
 });
 
	  }else{
		  window.location.href = '/';
	  }
  }
  getOrganizationList()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  let organization_name = localStorage.getItem("organization_name");
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }

  getCollectionList()
  {
	  let safeguard= localStorage.getItem("safeguard");
      let safeguard_array=JSON.parse(safeguard);
	  this.setState({
          ObjCollection: safeguard_array.collections,
		  loader: ''
      })
  }
  
  getMessageList(){
  let messages_template = this.state.messages_template;
  let ObjMessages = this.state.ObjMessages;
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organization_id = session_array.organization_id;

  messages_template["action"]="getMessageList";
  messages_template["organization_id"]=organization_id;
  messages_template["group_id"]=session_array.group_id;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.messages_template)
     })
      .then((response) => response.json())
      .then((response) => {
        const status=response.status;
        const message_list = response.message_list;
       if(status === "true")
       {
		localStorage.setItem('safeguard_messages', JSON.stringify(message_list));  
       }else{
		   localStorage.setItem('safeguard_messages', '');
	   }
 });
  }
  
  
  render() {
	if (localStorage.getItem('session')) {   
	let organization_details= localStorage.getItem("organization_details");
    let organization_details_array=JSON.parse(organization_details);
	let organization_messaging_enabled = organization_details_array.messaging_enabled;
	let token = localStorage.getItem("session");
	let token_array=JSON.parse(token);
	let user_level_2 = token_array.admin;
	let user_level_1 = token_array.organization_admin;
	let hide_profile = token_array.hide_profile;
	let hide_logout = token_array.hide_logout;
	let view_message_button = token_array.view_message_button;
	let user_super_admin = token_array.super_admin;
	let organization_token = localStorage.getItem("organization_details");
	let organization_token_array=JSON.parse(organization_token);
	let user_logo = this.state.filepath;
	let filepath = <img className="orgLogo" src={user_logo} /> ;
	let collectionList;
	
	//let collectionList_count = this.state.ObjCollection.length;
	
	if(this.state.ObjCollection && this.state.ObjCollection.length > 0)
	{
	collectionList = this.state.ObjCollection.map(v => (
      <li key={v.id}><a onClick={()=>this.redirectChart(v.id)} className="ember-view">{v.title}</a></li>
    ));
	}else{
	collectionList = '';	
	}
	

  
 return (
	
    <div id="ember361" className="ember-view ember-app"><div className="menu">
        <ul>
          <li className="homeicon"><a onClick={this.redirectDashboard}>h</a></li>
          <li className="back"><a>M</a></li>
          <li className="titlebar">{localStorage.getItem("organization_name")}</li>
          {hide_logout==0?<li className="logout"><a onClick= {this.logout}>o<span className="text">Logout</span></a></li>:''}
          {hide_profile==0?<li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>:''}
          <li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
          <li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
          {user_level_1==1 || user_super_admin==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a>c<span className="text">Admin</span></a></li>:''}
		  
        </ul>
      </div>
      <div id="application">
        <ul className="collections">
		{view_message_button==1?
		  <Fragment>
          <li className="messageButtonCell">
            <a className="ember-view danger intruder" onClick={this.GotoEmergency}><div className="buttonText">Emergency</div></a>
            <a className="ember-view danger" onClick={this.GotoShooter} style={{marginLeft:3,}}><div className="buttonText">Active Shooter</div></a>
          </li>
          <li className="messageButtonCell">
            <a className="ember-view" onClick={this.GotoSendMessage}><div className="buttonText">Send Message</div></a>
            <a className="ember-view" onClick={this.GotoViewMessage} style={{marginLeft:3,}}><div className="buttonText">View Messages</div></a>
          </li>
		  </Fragment>
		  :''}
		  <div className={this.state.loader}></div>
		 {collectionList}
        </ul>
      
      <div id="welcome">
        <div className="header">
          <div className="content">
          
            <div className="orgLogo-container">
            {filepath}
            </div>
          
          </div>
        </div>
        <div className="about">
          <h2>Mobile Emergency Response Plan (M.E.R.P.)</h2>
          <p>The MERP is designed to make your emergency plans and related information portable, immediately accessible, and easily revisable. It is available as both an online and mobile application. It provides your personnel with the tasks to be performed in the critical first moments of an incident. You can also add information to assist in your planning and recovery efforts.</p>
          <p>The MERP comes pre-loaded with content that is based upon best practices identified by emergency services agencies. This information is displayed in a simple task-based format. Simply add text and documents to customize the MERP to further meet the needs of your Organization.</p>
        </div>
        <div className="copyright">
        Copyright © 2022 Safeguard Risk Solutions LLC<br/><strong>Patent Pending</strong>
        </div>
      </div>
      
      </div>
      
      </div>
    );

}else{
		window.location.href = '/';
	}
  }
}

export default Collection;
