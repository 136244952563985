import React, { Component } from 'react';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import alasql from 'alasql';
import TinyMCE from 'react-tinymce';
import { Editor } from '@tinymce/tinymce-react';
import decode from 'decode-html';
import Base64 from 'base-64';
import $ from 'jquery';

class EditTasks extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
      viewtask_template: {},
	  fields: {},
	  collection_template: {},
	  card_template: {},
      ObjOrganizations: '',
      ObjViewTasks: [],
	  ObjCharts: [],
	  ObjCollection : [],
	  ObjCard : [],
	  ObjDestinationCard : [],
	  content: '',
	  chart_template: {},
	  textareaValue : ''
    }
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.redirectEventAids=this.redirectEventAids.bind(this);
	this.redirectPlan=this.redirectPlan.bind(this);
	this.handleEditorChange = this.handleEditorChange.bind(this);
	this.editProfile=this.editProfile.bind(this);
    }

	  redirectOrganization(){
	  this.props.history.push('/Organization');
	  }
	  editProfile(user_id,organizationid)
      {
	 this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
	  redirectDashboard(){
	  this.props.history.push('/Collection');
	  }
	  redirectPlan(e){
	  this.props.history.push('/collections/Collections?organization='+ e);
	  }
	  redirectViewTasks(organizationid,collectionid,chartid){
	  this.props.history.push('/ViewTasks?chartid='+chartid+'&organization='+organizationid+'&collectionid='+collectionid);
	  }
	  redirectEventAids(organizationid,collectionid) {
	  this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid);
	  }
  
  logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }

componentDidMount() {
  this.GetOrganizationDetail();
  this.GetCollectionDetails();
  this.GetViewTaskList();
  this.getCardDetails();
  this.getChartList();
}

handleEditorChange(e) {
this.setState({
content: e.target.getContent()
})
}
getChartList(){
let chart_template = this.state.chart_template;
let ObjCharts = this.state.ObjCharts;
chart_template["action"]="detailChart";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
chart_template["organizationid"]=organizationid;
chart_template["collectionid"]=collectionid;
chart_template["chartid"]=chartid;

fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.chart_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details=[] ;
	  details.push(response.details);
	 if(status === "true")
	 {
	  this.setState({
		ObjCharts: details
	})
	 }       
});
}
getCardDetails()
{
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');	
const card_id = query.get('card_id');
const chartid = query.get('chartid');
let card_template = this.state.card_template;
let ObjCard = this.state.ObjCard;
card_template["action"]="getCardDetails";
card_template["organizationid"]=organizationid;
card_template["collectionid"]=collectionid;
card_template["chartid"]=chartid;
card_template["card_id"]=card_id;

fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.card_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	  const destination_details = response.action_details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCard: details,
		ObjDestinationCard: destination_details
	})
	 }       
}); 

}
GetCollectionDetails(){
let collection_template = this.state.collection_template;
let ObjCollection = this.state.ObjCollection;
collection_template["action"]="detailCollection";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
collection_template["organizationid"]=organizationid;
collection_template["collectionid"]=collectionid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.collection_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCollection: details
	})
	 }       
});       }
GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }else{
	   }
 });
 }

handleValidation(){
let fields = this.state.fields;
let errors = {};
let formIsValid = true;
const query = new URLSearchParams(this.props.location.search);
const organization = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
const card_id = query.get('card_id');
const chart_content = this.state.content;
let editor_content =  $('.tox-edit-area__iframe').contents().find('#tinymce').html();
if(editor_content=='<p><br data-mce-bogus="1"></p>')
{
editor_content = '';	
}else{
editor_content = editor_content;	
}
editor_content = editor_content.replace(/&quot;/g, '');
editor_content = editor_content.replace(/\\/g, '');

var values = [];
$('.textarea_input').each(function(){
    values.push({ name: this.name, value: this.value }); 
});
/*alert(JSON.stringify(values));*/
//let action_title = document.getElementById('card_actions_attributes_content').value;
fields["action"]="updateCardContent";
fields["organizationid"]=organization;
fields["collectionid"]=collectionid;
fields["chartid"]=chartid;
fields["card_id"]=card_id;
fields["content"]=editor_content;
fields["action_title"]=values;
if(card_id==''){
 formIsValid = false;
}
this.setState({errors: errors});
return formIsValid;
}

cardSubmit(e){
e.preventDefault();
if(this.handleValidation()){
this.updateCardContentApi();
}else{
 alert("Mandatory Field Emplty!");
}
}

handleOnChange(event) {
    this.setState({
      textareaValue: event.target.value
    })
  }

updateCardContentApi()
{
	 
     const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const collectionid = query.get('collectionid');
	  const chartid = query.get('chartid');
	  const card_id = query.get('card_id');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {
           console.log(JSON.stringify(response));
           const status=response.status;
           if(status === "true")
           {
			  window.location.reload();
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
 
  GetViewTaskList(){
  let viewtask_template = this.state.viewtask_template;
  let ObjViewTasks = this.state.ObjViewTasks;
  viewtask_template["action"]="getViewTaskList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  const chartid = query.get('chartid');
  viewtask_template["organizationid"]=organizationid;
  viewtask_template["chartid"]=chartid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.viewtask_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const task_list = response.task_list;
         if(status === "true")
       {
        this.setState({
          ObjViewTasks: task_list
      })
       }else{
	   }
 });
}
  render() {
	  if (localStorage.getItem('session')) {
	  
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
	  
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization'); 
	const collectionid = query.get('collectionid');
	const chartid = query.get('chartid');
	let title = this.state.ObjCharts.map(v => (
	  v.title
    ));
    
	
	
	let action_title = this.state.ObjCard.action_content;
	
	let content = Base64.encode(this.state.ObjCard.content);
	
	
	let action_details = this.state.ObjDestinationCard.map(v => (
		  <div>
		<div className="row">
		<div className="col-md-6">
		<div className="form-group">
		<label>Action Title</label>
		
		<input
		type="text"
		className="form-control textarea_input"
		id={'card_actions_attributes_content' + v.destination_details.id}
		name={v.destination_details.id}
		rows="4"
		defaultValue={v.action_content}
		onChange={(event) => this.handleOnChange(event)} />
              </div>
            </div>
            <div className="col-md-1 arrow">
              <span className="glyphicon glyphicon-arrow-right"></span>
            </div>
            <div className="col-md-5">
              <div className="form-group"></div>
              <div className="form-group card-preview">
                  <div className={'title ' + v.destination_details.card_type_lower}>{v.destination_details.card_type}</div>
                  <div className={'card-preview ' + v.destination_details.card_type_lower}>
                   {ReactHtmlParser(v.destination_details.full_content)} 
                  </div>
              </div>
            </div>
          </div>
		  </div>
	));											
    return (
	<div className="bodyscroll">		
	<div className="navbar navbar-inverse navbar-static-top" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-left">
              <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
              <li className=""><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a rel="nofollow" title="Log Out" onClick= {this.logout}>Log Out</a></li>
              </ul>
            </div>
          </div>
        </div>
	
	<div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={()=>this.redirectEventAids(organizationid,collectionid)}>{this.state.ObjCollection.title}</a> » <a onClick={()=>this.redirectViewTasks(organizationid,collectionid,chartid)}>{title}</a> » {this.state.ObjCard.card_type}
          </div>
          </div>
          <div className="row">
          <div className="col-xs-12">
          <h1 style={{'padding-left' : '10px'}}>Edit Tasks</h1>
		<div className="container">
        <form
		acceptCharset="UTF-8"
		className="edit_card"
		id="edit_card"
		onSubmit= {this.cardSubmit.bind(this)}>
        <div className="row">
        <div className="col-md-6">
		<div className="form-group card_content_editor">
		<Editor
		initialValue={decode(Base64.decode(content.toString()))}
        init={{
			height: 400,
			menubar:true,
            statusbar: false,
			relative_urls : true,
            document_base_url : 'https://safeguardmerp.com/',
			link_assume_external_targets: true,
        plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks advcode fullscreen',
			'insertdatetime media table powerpaste hr code'
                 ],
		      contextmenu: "link image inserttable | cell row column deletetable | undo redo | copy | paste | cut",
			  toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code',
			  powerpaste_allow_local_images: true,
			  powerpaste_word_import: 'prompt',
			  powerpaste_html_import: 'prompt'
        }}
        onChange={this.handleEditorChange}
      />
		</div>
        <br/>
        </div>
        <div className="col-md-6">
        <label>Card preview</label>
        <div className="card-preview-container">
        <div className={'title ' + this.state.ObjCard.card_type_lower}>{this.state.ObjCard.card_type}</div>
        <div className={'card-preview ' + this.state.ObjCard.card_type_lower}>
        
		
		           <Editor
					initialValue={this.state.ObjCard.content_full}
					disabled = {true}
					init={{
						branding: false,
						menubar: false,
						toolbar: false,
						plugins: 'autoresize',
						readonly: true,
						statusbar:false
						}}
                    />
		
        </div>
        </div>
        </div>
        </div>
		
        <h2>Actions</h2>
		{action_details}
		
            <div className="form-group">
            <input className="btn btn-warning" name="apply" type="submit" value="Apply" />&nbsp;
            <input className="btn btn-success" name="save" type="submit" value="Save Tasks" />&nbsp;
		    <a className="btn btn-default" onClick={()=>this.redirectViewTasks(organizationid,collectionid,chartid)}>Cancel</a>
            </div>
			
            </form>
			
			
			
		    </div>
            </div>
          </div>
    </div>
	</div>
    );
	
	
  
	  }else{
		  window.location.href = '/';
	  }
  }
}

export default EditTasks;