import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string'
import AddGroup from './AddUser'
import Pagination from "react-js-pagination";
import ReactExport from "react-export-excel";
import { Dropdown } from 'react-bootstrap';

import $ from 'jquery';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class User extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
	  user_count_template: {},
      user_template: {},
	  import_template: {},
      user_delete_template: {},
      ObjOrganizations: [],
	  ObjOrganization: '',
	  activePage: 1,
	  serach_name: '',
	  total_count: 0,
	  show_all_mode:0,
	  current_range: 30,
	  start_limit: 0,
	  start_index: 1,
	  per_page_count:0,
	  prefix_limit:0,
	  dataSet1: [],
	  dataSet2: [],
	  dataSet3: [],
      ObjUser: [],
	  ObjImport: []
    }
    this.handleAddUser=this.handleAddUser.bind(this);
	this.showAllRecords=this.showAllRecords.bind(this);
	this.showPaginatedRecords=this.showPaginatedRecords.bind(this);
	this.handleImportCSV=this.handleImportCSV.bind(this);
	this.handleImportExcel=this.handleImportExcel.bind(this);
	this.handleImportAutoCSV=this.handleImportAutoCSV.bind(this);
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.editProfile=this.editProfile.bind(this);
 }

 
 redirectDashboard(){
 this.props.history.push('/Collection');
 }
 
 editProfile(user_id,organizationid)
 {
	 this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
 }
 
 handleAddUser(){
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/AddUser?organization='+ token);
  }
  
  showAllRecords(){
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/users/User?organization='+ token+'&status=Active&sort_param=&sort_mode=&current_range=0&show_all=1');
  window.location.reload();
  }
  
  showPaginatedRecords(){
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');	  
  this.props.history.push('/users/User?organization='+ token+'&status=Active&sort_param=&sort_mode=');
  window.location.reload();
  }
  
  handleImportCSV()
  {
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/ImportCSV?organization='+ token);	  
  }
  
  handleImportExcel()
  {
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/ImportExcel?organization='+ token);	  
  }
  
  handleImportAutoCSV()
  {
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/ImportAutoCSV?organization='+ token);
  }
handlePageChange(pageNumber) {
	  let current_range;
	  let previous_number;
	  let previous_range;
	  let start_limit;
	  let start_index;
	  let total;
	  let new_total;
	  
	  previous_number = pageNumber - 1;
	  current_range = 30 * pageNumber;
	  previous_range = 30 * previous_number;
	  start_limit = previous_range;
	  start_index = previous_range + 1;
	  
	  if(pageNumber==1)
	  {
	  new_total = 0;
	  }else{
		  total = this.state.current_range * previous_number;
	      new_total = total + 1;
	  }
	  
	  let user_template = this.state.user_template;
	  let ObjUser = this.state.ObjUser;
	  user_template["action"]="getUserList";
	  const query = new URLSearchParams(this.props.location.search);
	  const organizationid = query.get('organization');
	  user_template["organizationid"]=organizationid;
	  user_template["start_limit"]=new_total;
      user_template["current_range"]=this.state.current_range;
	  
	  user_template["first_name"]=query.get('first_name');
	  user_template["last_name"]=query.get('last_name');
	  user_template["email"]=query.get('email');
	  user_template["school"]=query.get('school');
	  user_template["group"]=query.get('group');
	  user_template["status"]=query.get('status');
	  user_template["email_status"]=query.get('email_status');
	  
	  fetch(global.api_url,
		 {
			 method: 'POST',
			 headers: {
				 Accept: 'application/json',
				 'Content-Type': 'application/json'
			 },
			 body: JSON.stringify(this.state.user_template)
		 })
		  .then((response) => response.json())
		  .then((response) => { 
			const status=response.status;
			const user_list = response.user_list;
			const total_count = response.total_count;
		   if(status === "true")
		   {
			this.setState({
			  ObjUser: response.user_list,
			  per_page_count:total_count
		  })
		   }       
	 });
	this.setState({
          activePage: pageNumber,
		  prefix_limit: new_total
      })	  
}

  getOrganizationName()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }
  
  getUserImportList()
  {
	  let import_template = this.state.import_template;
	  let ObjImport = this.state.ObjImport;
	  import_template["action"]="getUserImportList";
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  import_template["organizationid"]=organizationid;
	  
	  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.import_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		const addition=response.addition;
		const changes=response.changes;
		const deactivation=response.deactivation;
       if(status === "true")
       {
        this.setState({
          dataSet1: addition,
		  dataSet2: changes,
		  dataSet3: deactivation
      })
       }       
 });
  }

  GetUserList(){	
  let user_template = this.state.user_template;
  let ObjUser = this.state.ObjUser;
  let email_status_value = '';
  user_template["action"]="getUserList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  const current_range = query.get('current_range');
  let current_page_records;
  if(current_range!=undefined)
  {
   current_page_records = current_range;  
  }else{
	current_page_records = this.state.current_range;  
  }
  user_template["organizationid"]=organizationid;
  user_template["start_limit"]=this.state.start_limit;
  user_template["current_range"]=current_page_records;
  
  user_template["first_name"]=query.get('first_name');
  user_template["last_name"]=query.get('last_name');
  user_template["email"]=query.get('email');
  user_template["school"]=query.get('school');
  user_template["group"]=query.get('group');
  user_template["status"]=query.get('status');
  user_template["email_status"]=query.get('email_status');
  user_template["sort_param"]=query.get('sort_param');
  user_template["sort_mode"]=query.get('sort_mode');
 
  
  document.getElementById('grid_first_name').value = query.get('first_name');
  document.getElementById('grid_last_name').value = query.get('last_name');
  document.getElementById('grid_email').value = query.get('email');
  document.getElementById('grid_school').value = query.get('school');
  document.getElementById('grid_groups_name').value = query.get('group');
  if(query.get('status')!=undefined)
  {
	  document.getElementById('grid_active').value = query.get('status');
  }else{
	  document.getElementById('grid_active').value = 'Active';
  }
  
  if(query.get('email_status')!=undefined)
  {
	  document.getElementById('email_active').value = query.get('email_status');
  }else{
	  document.getElementById('email_active').value = 'All';
  }
  
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.user_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const user_list = response.user_list;
		const total_count = response.total_count;
       if(status === "true")
       {
        this.setState({
          ObjUser: response.user_list,
		  per_page_count: total_count
      })
       }       
 });
 
}
  
getUserCount()
{
	let user_count_template = this.state.user_count_template;
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	let session = localStorage.getItem("session");
	let session_array=JSON.parse(session);
    let super_admin = session_array.super_admin;
	let id = session_array.id;
	user_count_template["action"]="getUserTotalCount";
	user_count_template["organizationid"]=organizationid;
	
	  user_count_template["first_name"]=query.get('first_name');
	  user_count_template["last_name"]=query.get('last_name');
	  user_count_template["email"]=query.get('email');
	  user_count_template["school"]=query.get('school');
	  user_count_template["group"]=query.get('group');
	  user_count_template["status"]=this.menu.value;
	  user_count_template["super_admin"]=super_admin;
	
	fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.user_count_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		const total_count=response.total_count;
       if(status === "true")
       {
        this.setState({
		  total_count: total_count
      })
		
       }       
 });
}

  GetOrganizationDetail(){
    let organization_template = this.state.organization_template;
    let ObjOrganizations = this.state.ObjOrganizations;
    organization_template["action"]="getOrganizationDetails"; 
    const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
    organization_template["organizationid"]=organizationid; 
    fetch(global.api_url,
       {
           method: 'POST',
           headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(this.state.organization_template)
       })
        .then((response) => response.json())
        .then((response) => { 
          const status=response.status;
          const name = response.name;
         if(status === "true")
         {
          this.setState({
            ObjOrganizations: response.name
        })
         }       
   });
   
  }

  logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
handleFirstNameKeyPress = (event) => {
  if(event.key == 'Enter'){
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	
	const current_range = query.get('current_range');
	const show_all = query.get('show_all');
	
	
	const search_first_name = event.target.value;
	const search_last_name = document.getElementById('grid_last_name').value;
	const search_email = document.getElementById('grid_email').value;
	const search_school = document.getElementById('grid_school').value;
	const search_group = document.getElementById('grid_groups_name').value;
	let search_status = this.menu.value;
	
	if(current_range!=undefined && show_all!=undefined)
	{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&current_range=0&show_all=1');
	}else{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'');
	}
	
	
	
	window.location.reload();	
  }
}

handleLastNameKeyPress = (event) => {
  if(event.key == 'Enter'){
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const current_range = query.get('current_range');
	const show_all = query.get('show_all');
	const search_last_name = event.target.value;
	const search_first_name = document.getElementById('grid_first_name').value;
	const search_email = document.getElementById('grid_email').value;
	const search_school = document.getElementById('grid_school').value;
	const search_group = document.getElementById('grid_groups_name').value;
	let search_status = this.menu.value;
	
	if(current_range!=undefined && show_all!=undefined)
	{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&current_range=0&show_all=1');
	}else{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'');
	}
	
	
	
	window.location.reload();	
  }
}

handleEmailKeyPress = (event) => {
  if(event.key == 'Enter'){
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const current_range = query.get('current_range');
	const show_all = query.get('show_all');
	const search_email = event.target.value;
	const search_first_name = document.getElementById('grid_first_name').value;
	const search_last_name = document.getElementById('grid_last_name').value;
	const search_school = document.getElementById('grid_school').value;
	const search_group = document.getElementById('grid_groups_name').value;
	let search_status = this.menu.value;
	
	if(current_range!=undefined && show_all!=undefined)
	{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&current_range=0&show_all=1');
	}else{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'');
	}
	window.location.reload();	
  }
}

handleSchoolKeyPress = (event) => {
  if(event.key == 'Enter'){
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const current_range = query.get('current_range');
	const show_all = query.get('show_all');
	const search_school = event.target.value;
	const search_first_name = document.getElementById('grid_first_name').value;
	const search_last_name = document.getElementById('grid_last_name').value;
	const search_email = document.getElementById('grid_email').value;
	const search_group = document.getElementById('grid_groups_name').value;
	let search_status = this.menu.value;
	
	if(current_range!=undefined && show_all!=undefined)
	{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&current_range=0&show_all=1');
	}else{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'');
	}
	
	window.location.reload();	
  }
}

handleGroupKeyPress = (event) => {
  if(event.key == 'Enter'){
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const current_range = query.get('current_range');
	const show_all = query.get('show_all');
	const search_group = event.target.value;
	const search_first_name = document.getElementById('grid_first_name').value;
	const search_last_name = document.getElementById('grid_last_name').value;
	const search_email = document.getElementById('grid_email').value;
	const search_school = document.getElementById('grid_school').value;
	let search_status = this.menu.value;
	
	if(current_range!=undefined && show_all!=undefined)
	{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&current_range=0&show_all=1');
	}else{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'');
	}

	window.location.reload();	
  }
}

handleStatusKeyPress = (event) => {
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const current_range = query.get('current_range');
	const show_all = query.get('show_all');
	const search_status = event.target.value;
	const email_status = document.getElementById('email_active').value;
	const search_first_name = document.getElementById('grid_first_name').value;
	const search_last_name = document.getElementById('grid_last_name').value;
	const search_email = document.getElementById('grid_email').value;
	const search_school = document.getElementById('grid_school').value;
	const search_group = document.getElementById('grid_groups_name').value;
	
	
	if(current_range!=undefined && show_all!=undefined)
	{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&email_status='+email_status+'&current_range=0&show_all=1');
	}else{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&email_status='+email_status+'');
	}
	
	window.location.reload();	
  
}

handleEmailStatusKeyPress = (event) => {
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const current_range = query.get('current_range');
	const show_all = query.get('show_all');
	const search_status = document.getElementById('grid_active').value;
	const email_status = event.target.value;
	const search_first_name = document.getElementById('grid_first_name').value;
	const search_last_name = document.getElementById('grid_last_name').value;
	const search_email = document.getElementById('grid_email').value;
	const search_school = document.getElementById('grid_school').value;
	const search_group = document.getElementById('grid_groups_name').value;
	
	if(current_range!=undefined && show_all!=undefined)
	{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&email_status='+email_status+'&current_range=0&show_all=1');
	}else{
		this.props.history.push('/users/User?organization='+organizationid+'&first_name='+search_first_name+'&last_name='+search_last_name+'&email='+search_email+'&school='+search_school+'&group='+search_group+'&status='+search_status+'&email_status='+email_status+'');
	}	
	window.location.reload();	
  
}

componentDidMount() {
this.GetOrganizationDetail();
this.GetUserList();
this.getUserCount();
this.getOrganizationName();
this.getUserImportList();
}

editUser(userid,organizationid){
this.props.history.push('/EditUser?organization='+organizationid+'&user='+userid);
}
sortUser(sort_parameter){
let sort_order = '';	
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const sort_mode = query.get('sort_mode');
if(sort_mode=='DESC')
{
	sort_order = 'ASC';
}else if(sort_mode=='ASC'){
	sort_order = 'DESC';
}else{
	sort_order = 'DESC';
}
this.props.history.push('/users/User?organization='+organizationid+'&status=Active&sort_param='+sort_parameter+'&sort_mode='+sort_order);
window.location.reload();
}

deleteUserSubmit(userid,organizationid,login_user_id)
{
  if (window.confirm("Are you sure you want to delete this user")) {
  this.deleteUser(userid,organizationid,login_user_id);
}
}

deleteUser(userid,organizationid,login_user_id)
{
const query = new URLSearchParams(this.props.location.search);
const token = query.get('organization');  
let user_delete_template = this.state.user_delete_template;
user_delete_template["organizationid"]=organizationid;
user_delete_template["userid"]=userid;
user_delete_template["login_user_id"]=login_user_id;
user_delete_template["action"]="deleteUser";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.user_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {	
	   this.GetUserList();
	   this.props.history.push('/users/User?organization='+token+'&action=deleted');	
	   //window.location.reload();
    }       
});
}



redirectOrganization(){
  this.props.history.push('/Organization');
  }

GetGroupDetails(){
  let group_template = this.state.group_template;
  let ObjGroup = this.state.ObjGroup;
  group_template["action"]="getGroupDetails";
  const groupid = 10;
  group_template["groupid"]=groupid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.user_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const user_list = response.user_list;
       if(status === "true")
       {
        this.setState({
          ObjUser: response.user_list
      })
       }       
 });
 
}

render(){
	if (localStorage.getItem('session')) {
	
	
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
let show_mode = 0;
const show_all_mode = query.get('show_all');
if(show_all_mode!=undefined)
{
	show_mode = 1;
}
const action = query.get('action');
const user_import = query.get('import');
let token = localStorage.getItem("session");
let token_array=JSON.parse(token);
let user_id = token_array.id;
let super_admin = token_array.super_admin;
let user_level_2 = token_array.admin;
let user_level_1 = token_array.organization_admin;
let organization_id = token_array.organization_id;

let action_message;
let action_button;
if(action=='created')
{
action_message = <div data-alert="" className="alert alert-success">User successfully created!</div>;
action_button = <a className="invisible">No</a>;
}else if(action=='deleted')
{
action_message = <div data-alert="" className="alert alert-success">User successfully deleted!</div>;
action_button = <a className="invisible">No</a>;
}else if(action=='import')
{
action_button = <a className="btn btn-success">Download Import Report</a>;
}else{
  action_message = '';
  action_button = '';
}
const start_index = this.state.prefix_limit;
const per_page_count = this.state.per_page_count;
const per_page_total = start_index + per_page_count;
let userList = this.state.ObjUser.map(v => (
<tr className="odd" key={v.id}>
<td align="middle">{v.first_name}</td>
<td align="middle">{v.last_name}</td>
<td className="sorted">{v.email}</td>
<td align="middle">{v.school}</td>
<td align="middle"><div className={"label " + v.active_class}>{v.active_text}</div></td>
<td align="middle">{v.group_name}</td>
<td align="middle">
{v.super_admin!=0?<div className={v.super_admin_class}>{v.super_admin_text}</div>:''}<br/>
{v.organization_admin!=0?<div className={v.organization_admin_class}>{v.organization_admin_text}</div>:''}<br/> 
{v.admin!=0?<div className={v.admin_class}>{v.admin_text}</div>:''} 
<br/><div className="label label-default">{v.group_name}</div>
</td>
<td align="middle">{v.email_sent==0?'N/A': v.email_sent==1?'Sent': v.email_sent==2?'Not Sent': ''}</td>
<td>{user_level_1==1 || super_admin==1 ?<a className="btn btn-sm btn-default" onClick={()=>this.editUser(v.id,organizationid)} data-original-title="" title="">Edit</a>:''}</td>
<td>{super_admin==1 || user_level_1==1 ?<a className="btn btn-sm btn-default" onClick={()=>this.deleteUserSubmit(v.id,organizationid,user_id)} data-original-title="" title="">Delete</a>:''}</td>
</tr>
));
	
		
    return (
      <div className="bodyscroll">
      <div className="navbar navbar-inverse navbar-static-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-left">
            <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganization}</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
                <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
              <li className=""><a data-original-title="" onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a data-method="delete" onClick= {this.logout}  rel="nofollow">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container">
    <div className="row">
            <div className="col-xs-12">
            <a className="" onClick={this.redirectOrganization}>Admin</a> » <a className="" onClick={this.redirectOrganization}>Organization</a> » Users
            </div>
          </div> 
          <div className="row">
          <div className="col-xs-12">
         {action_message}
          <h2>
          <span className="pull-right">
		  
		  <ExcelFile element={action_button}>
                <ExcelSheet data={this.state.dataSet1} name="Addition">
                    <ExcelColumn label="First Name" value="first_name"/>
                    <ExcelColumn label="Last Name" value="last_name"/>
                    <ExcelColumn label="Email Address" value="email"/>
					<ExcelColumn label="Group" value="groups"/>
					<ExcelColumn label="Location" value="location"/>
					<ExcelColumn label="Password" value="password"/>
					<ExcelColumn label="User Level 1" value="user_level_1"/>
					<ExcelColumn label="User Level 2" value="user_level_2"/>
					<ExcelColumn label="Status" value={(col) => col.status ==1 ? "Active" : "De-Active"} />
                </ExcelSheet>
				<ExcelSheet data={this.state.dataSet2} name="Changes">
                    <ExcelColumn label="First Name" value="first_name"/>
                    <ExcelColumn label="Last Name" value="last_name"/>
                    <ExcelColumn label="Email Address" value="email"/>
					<ExcelColumn label="Group" value="groups"/>
					<ExcelColumn label="Location" value="location"/>
					<ExcelColumn label="Password" value="password"/>
					<ExcelColumn label="User Level 1" value="user_level_1"/>
					<ExcelColumn label="User Level 2" value="user_level_2"/>
					<ExcelColumn label="Status" value="status" value={(col) => col.status ==1 ? "Active" : "De-Active"} />
                </ExcelSheet>
				<ExcelSheet data={this.state.dataSet3} name="De-activation">
                    <ExcelColumn label="First Name" value="first_name"/>
                    <ExcelColumn label="Last Name" value="last_name"/>
                    <ExcelColumn label="Email Address" value="email"/>
					<ExcelColumn label="Group" value="groups"/>
					<ExcelColumn label="Location" value="location"/>
					<ExcelColumn label="Password" value="password"/>
					<ExcelColumn label="User Level 1" value="user_level_1"/>
					<ExcelColumn label="User Level 2" value="user_level_2"/>
					<ExcelColumn label="Status" value="status" value={(col) => col.status ==1 ? "Active" : "De-Active"} />
                </ExcelSheet>
            </ExcelFile>		  
          &nbsp;
		  {user_level_1==1 || super_admin==1 ?<a className="btn btn-success" onClick={this.handleAddUser}>Add User</a>:''}&nbsp;
		  {user_level_1==1 || super_admin==1 ?
		  <Dropdown className="import_button">
		  <Dropdown.Toggle variant="success" id="dropdown-basic" className="button-dropdown">Import</Dropdown.Toggle>
		  <Dropdown.Menu>
			<Dropdown.Item onClick={this.handleImportCSV}>CSV</Dropdown.Item>
			<Dropdown.Item onClick={this.handleImportExcel}>Excel</Dropdown.Item>
		  </Dropdown.Menu>
		</Dropdown>:''}&nbsp;
          {user_level_1==1 || super_admin==1 ?<a className="btn btn-success" onClick={this.handleImportAutoCSV}>Import Auto CSV/Excel</a>:''}
         </span>
         {this.state.ObjOrganizations} » Users
          </h2>
          
         <div className="wice-grid-container table-responsive" data-grid-name="grid" id="grid">
           <div id="grid_title"></div>
    <table className="table-striped table-bordered table wice-grid">
      <thead>
        <tr className="wice-grid-title-row">
          <th className=""><a data-original-title="" title="" onClick={()=>this.sortUser('first_name')}>First</a></th>
          <th className=""><a data-original-title="" title="" onClick={()=>this.sortUser('last_name')}>Last</a></th>
          <th className="sorted"><a className="asc"  data-original-title="" title="" onClick={()=>this.sortUser('email')}>Email</a></th>
          <th className=""><a  data-original-title="" title="" onClick={()=>this.sortUser('school')}>Location</a></th>
          <th className=""><a data-original-title="" title="">Status</a></th>
          <th className=""><a  data-original-title="" title="">Group</a></th>
          <th className="">User Level</th>
		  <th className="">Email Sent Status</th>
          <th className=""></th>
          <th className=""></th>
        </tr>
          <tr className="wg-filter-row" id="grid_filter_row">
          <th className="">
		  
		  <input className="form-control input-sm"
		  id="grid_first_name"
		  name="grid_first_name"
		  size="8"
		  type="text"
		  onKeyPress={this.handleFirstNameKeyPress} />
		  </th>
          <th className="">
		  <input className="form-control input-sm"
		  id="grid_last_name"
		  name="grid_last_name"
		  size="8"
		  type="text"
		  onKeyPress={this.handleLastNameKeyPress} />
		  </th>
          <th className="sorted sorted">
		  <input className="form-control input-sm"
		  id="grid_email"
		  name="grid_email"
		  size="8"
		  type="text"
		  onKeyPress={this.handleEmailKeyPress} />
		  </th>
          <th className="">
		  <input className="form-control input-sm"
		  id="grid_school"
		  name="grid_school"
		  size="8"
		  type="text"
		  onKeyPress={this.handleSchoolKeyPress} />
		  </th>
          <th className="">
            <div className="custom-dropdown-container">
              <select className="custom-dropdown form-control"
			  id="grid_active"
			  name="grid_active"
			  defaultValue="Active"
			  ref = {(input)=> this.menu = input}
			  onChange={this.handleStatusKeyPress}>
              <option value="Active">Active</option>
              <option value="Disabled">Disabled</option>
			  <option value="All">All</option>
            </select>
            <span className="expand-multi-select-icon clickable" title="Expand"></span>
            <span className="collapse-multi-select-icon clickable" title="Collapse"></span>
          </div>
          </th>
          <th className="">
            <input className="form-control input-sm"
			id="grid_groups_name"
			name="grid_groups_name"
			size="8"
			type="text"
			onKeyPress={this.handleGroupKeyPress} />
			</th>
          <th className="text-right text-right"></th>
          <th className="">
		  
		  <div className="custom-dropdown-container">
              <select className="custom-dropdown form-control"
			  id="email_active"
			  name="email_active"
			  defaultValue="Active"
			  onChange={this.handleEmailStatusKeyPress}>
              <option value="na">N/A</option>
              <option value="sent">Sent</option>
			  <option value="failed">Failed</option>
			  <option value="All">All</option>
            </select>
            <span className="expand-multi-select-icon clickable" title="Expand"></span>
            <span className="collapse-multi-select-icon clickable" title="Collapse"></span>
          </div>
		  
		  
		  </th>
		  <th className=""></th>
          <th className=" filter_icons">
            <div className="submit clickable" id="grid_submit_grid_icon" title="Filter"></div> 
            <div className="reset clickable" id="grid_reset_grid_icon" title="Reset"></div>
          </th>
        </tr>
        </thead>
        <tbody>
         {userList}
        </tbody>
      </table>
	  {show_mode==0 ?
	  <div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total_count}
          pageRangeDisplayed={5}
		  onChange={this.handlePageChange.bind(this)}
        />
      </div>
	  : '' }
<div className="wg-data">
<div className="pagination_status">{this.state.prefix_limit}-{per_page_total} / {this.state.total_count} {super_admin==1 && show_mode==0 ?<a className="wg-show-all-link" href="#" title="" data-original-title="Show all records" onClick={this.showAllRecords}>Show All</a>:<a className="wg-show-all-link" href="#" title="" data-original-title="back to paginated view" onClick={this.showPaginatedRecords}>back to paginated view</a>}</div>
</div>
</div>
          </div>   
          </div> 
    </div>
</div>
    );
	
	
  
	}else{
		 window.location.href = '/';
	}
  }
}

export default User;
