import React, { Component } from 'react';
import queryString from 'query-string'
import AddFileFolder from './AddFileFolder'
import AddFile from './AddFile'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

class Attachment extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
      attachment_template: {},
      ObjOrganizations: '',
	  sortbyName: '',
	  sortbyDate: '',
	  folder_delete_template : {},
      ObjAttachment: []
    }
    this.addFolder=this.addFolder.bind(this);
	this.addFile=this.addFile.bind(this);
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.editProfile=this.editProfile.bind(this);
	this.sortbyName=this.sortbyName.bind(this);
	this.sortbyDate=this.sortbyDate.bind(this);
	this.GetAttachmentList=this.GetAttachmentList.bind(this);
 }
  sortbyName()
  {
  let attachment_template = this.state.attachment_template;
  let ObjAttachment = this.state.ObjAttachment;
  attachment_template["action"]="getAttachmentListbyName";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  attachment_template["organizationid"]=organizationid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.attachment_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const attachment_details = response.list;
		//console.log(JSON.stringify(attachment_details));
       if(status === "true")
       {
        this.setState({
          ObjAttachment: attachment_details
      })
       }       
 });
  
  }
  
  sortbyDate()
  {
  let attachment_template = this.state.attachment_template;
  let ObjAttachment = this.state.ObjAttachment;
  attachment_template["action"]="getAttachmentListbyDate";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  attachment_template["organizationid"]=organizationid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.attachment_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const attachment_details = response.list;
		//console.log(JSON.stringify(attachment_details));
       if(status === "true")
       {
        this.setState({
          ObjAttachment: attachment_details
      })
       }       
 });
  
  
  }
  addFolder(){
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/AddFileFolder?organization='+ token);
  }
  addFile(){
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/AddFile?organization='+ token);
  }
  redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
  editProfile(user_id,organizationid){
  this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
  }	 
  logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
componentDidMount() {
  this.GetOrganizationDetail();
  this.GetAttachmentList();
}

redirectOrganization(){
  this.props.history.push('/Organization');
  }

GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }       
 });
 
}

  GetAttachmentList(){
  let attachment_template = this.state.attachment_template;
  let ObjAttachment = this.state.ObjAttachment;
  
  attachment_template["action"]="getAttachmentList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  attachment_template["organizationid"]=organizationid;
 
  //console.log(JSON.stringify(this.state.attachment_template));
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.attachment_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const attachment_details = response.list;
		//console.log(JSON.stringify(attachment_details));
       if(status === "true")
       {
        this.setState({
          ObjAttachment: attachment_details
      })
       }       
 });
 
}
deleteFolderSubmit(folderid,userid,organizationid)
{	
  if (window.confirm("Are you sure you want to delete this Folder")) {
  this.deleteFolder(folderid,userid,organizationid);
  }
}

deleteFileSubmit(folderid,userid,organizationid)
{	
  if (window.confirm("Are you sure you want to delete this File")) {
  this.deleteFile(folderid,userid,organizationid);
  }
}

deleteFolder(folderid,userid,organizationid)
{ 
let folder_delete_template = this.state.folder_delete_template;
folder_delete_template["folderid"]=folderid;
folder_delete_template["userid"]=userid;
folder_delete_template["organizationid"]=organizationid;
folder_delete_template["action"]="deleteFolder";
fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.folder_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	   window.location.reload();
    }       
});
}

deleteFile(folderid,userid,organizationid)
{ 
let folder_delete_template = this.state.folder_delete_template;
folder_delete_template["folderid"]=folderid;
folder_delete_template["userid"]=userid;
folder_delete_template["organizationid"]=organizationid;
folder_delete_template["action"]="deleteFile";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.folder_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	   window.location.reload();
    }       
});
}
  redirectToEditFolder(folderid,organizationid){
    this.props.history.push('/EditFileFolder?organization='+organizationid+'&folderid='+folderid);
  }
  
  redirectToEditFile(folderid,organizationid){
    this.props.history.push('/EditFile?organization='+organizationid+'&folderid='+folderid);
  }
  
  render() {
	  if (localStorage.getItem('session')) {
	  
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
    const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const file_list = this.state.ObjAttachment;
		
    return (
      <div className="bodyscroll">
      <div className="navbar navbar-inverse navbar-static-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-left">
            <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization} data-original-title="">Admin</a></li>
              <li><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a onClick= {this.logout} data-method="delete" rel="nofollow" data-original-title="">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » <a>Supporting Files</a>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h2>
      <span className="pull-right">
	     
		  <Dropdown className="import_button">
		  <Dropdown.Toggle variant="success" id="dropdown-basic" className="button-dropdown">Sort</Dropdown.Toggle>
		  <Dropdown.Menu>
			<Dropdown.Item onClick={this.sortbyName}>Sort by name</Dropdown.Item>
			<Dropdown.Item onClick={this.sortbyDate}>Sort by date</Dropdown.Item>
		  </Dropdown.Menu>
		</Dropdown>&nbsp;
          <a className="btn btn-success" onClick={this.addFolder}>Add Folder</a>&nbsp;
          <a className="btn btn-success" onClick={this.addFile}>Add File</a>
      </span>
       Supporting Files
    </h2>
    
    <table className="table table-striped">
      <tbody>
        <tr>
          <th>Folder/File Name</th>
          <th className="text-right">Actions</th>
       </tr>
	   {file_list?
	   this.state.ObjAttachment.map((v, i) => {
       return [
       <tr key={i}>
	   {v.type=='Folder'?
          <td><img src="/assets/images/folder.png" alt="folder" width="20" height="20" /> <strong>{v.name}</strong></td>
		  : <td><img src="/assets/images/file.png" alt="file" width="20" height="20" /> {v.name}</td>
	   }
          <td className="text-right">
		  {v.type=='Folder'?
            <a onClick={()=>this.redirectToEditFolder(v.folderid,v.organization_id)}><img src="/assets/images/edit.png" alt="remove" height="30px" width="30px" /></a>
			: <a onClick={()=>this.redirectToEditFile(v.folderid,v.organization_id)}><img src="/assets/images/edit.png" alt="remove" height="30px" width="30px" /></a>
		  }
		   &nbsp;
		  {v.type=='Folder'?
            <a data-method="delete" rel="nofollow" onClick={()=>this.deleteFolderSubmit(v.folderid,user_id,organization_id)}><img src="/assets/images/remove.png" alt="remove" height="25px" width="25px" /></a>
			: <a data-method="delete" rel="nofollow" onClick={()=>this.deleteFileSubmit(v.folderid,user_id,organization_id)}><img src="/assets/images/remove.png" alt="remove" height="25px" width="25px" /></a>
		  }
          </td>
        </tr>,
		...[
			v.subfolder.map((item, k) => (
		<tr key={k}>
		<td className="pLeft30"><img src="/assets/images/folder.png" alt="file" width="18" height="18" /> <strong>/{item.name}</strong></td>
		<td className="text-right"></td>
		</tr>
		))
            ],
		...[
			v.file_details.map((item, j) => (
		<tr key={j}>
		{item.source=='Folder'?
		<td className="pLeft30"><img src="/assets/images/file.png" alt="file" width="18" height="18" /> {item.name}</td>
		: <td className="subfolder_row pLeft30"><img src="/assets/images/folder.png" alt="folder" width="18" height="18" /> <strong>{item.subfolderName}</strong><p><img src="/assets/images/file.png" alt="file" width="18" height="18" /> {item.name}</p></td>
		}
		<td className="text-right">
		{v.type=='File'?
            <a onClick={()=>this.redirectToEditFolder(item.folderid,item.organization_id)}><img src="/assets/images/edit.png" alt="remove" height="30px" width="30px" /></a>
			: <a onClick={()=>this.redirectToEditFile(item.folderid,item.organization_id)}><img src="/assets/images/edit.png" alt="remove" height="30px" width="30px" /></a>
		  }
		   &nbsp;
		  {v.type=='File'?
            <a data-method="delete" rel="nofollow" onClick={()=>this.deleteFolderSubmit(item.folderid,user_id,organization_id)}><img src="/assets/images/remove.png" alt="remove" height="25px" width="25px" /></a>
			: <a data-method="delete" rel="nofollow" onClick={()=>this.deleteFileSubmit(item.folderid,user_id,organization_id)}><img src="/assets/images/remove.png" alt="remove" height="25px" width="25px"  /></a>
		  }
		 
		  
		  </td>
		</tr>
		))
            ]

    ];
       })
	   :null}
	   
    </tbody>
	</table>
    </div>
    </div>  
    </div>
    </div>
    );
	
	
  
	  }else{
		  window.location.href = '/';
	  }
  }
}
export default Attachment;