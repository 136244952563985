import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import axios from 'axios';
import Dropzone from 'react-dropzone';
class DragFile extends Component {
	
  constructor(props) {
    super(props);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.state = {
      selectedFiles: undefined,
      progressInfos: [],
      message: [],
      fileInfos: [],
	  fields:[]
    };
  }

  componentDidMount() {
  }
  uploadFiles() {
    const selectedFiles = this.state.selectedFiles;
    console.log(selectedFiles);
	const data = new FormData()
	data.append('file_attachment_attachment', this.state.selectedFiles[0])
	axios.post("https://expressapi.safeguardmerp.com/uploadimage", data, {
   })
   .then(res => {
     let fields = this.state.fields;
	 console.log(res.data);
	  fields["filepath"] = res.data.path;
	  console.log(this.state.fields);
  })
  }
  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
    }
  }
  render() {
    const { selectedFiles, progressInfos, message, fileInfos } = this.state;
    return (
      <div>
        <div className="file-container">
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section className="container fileDrop">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {selectedFiles &&
                  Array.isArray(selectedFiles) &&
                  selectedFiles.length ? (
                    <div className="selected-file">
                      {selectedFiles.length > 3
                        ? `${selectedFiles.length} files`
                        : selectedFiles.map((file) => file.name).join(", ")}
                    </div>
                  ) : (
                    `Drag and drop files here, or click to select files`
                  )}
                </div>
                <aside className="selected-file-wrapper">
                  <button
                    className="btn btn-success"
                    disabled={!selectedFiles}
                    onClick={this.uploadFiles}
                  >
                    Upload
                  </button>
                </aside>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}
export default DragFile;