import React, { Component } from 'react';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';

import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Base64 from 'base-64';
class PrintPDF extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:null,
            password:null,
            newpassword:null,
            confirmpassword:null,
			ObjCollection : [],
            userid:null,
            organizationid:null
        }
        this.GotoHome=this.GotoHome.bind(this);
     }
	 
	 //getOrganizationList()
//     {
//	  let session= localStorage.getItem("session");
//      let session_array=JSON.parse(session);
//	  this.setState({
//          ObjOrganization: session_array.organization
//      })
//      }

     componentDidMount(){}

     GotoHome(){
		let query = new URLSearchParams(this.props.location.search);
		let collectionid = query.get('collectionid');
        this.props.history.push('/Charts?collectionid='+collectionid);
      }
    
     render(){
		let query = new URLSearchParams(this.props.location.search);
        let filepath = query.get('filepath');
		filepath = Base64.decode(filepath);
		//filepath = "https://safeguardmerp.com"+filepath;
		 const docs = [
			{ uri: filepath }
		  ];
		 //docs.header("Access-Control-Allow-Origin", "*");
         //docs.header("Access-Control-Allow-Headers", "X-Requested-With");
         return(
            <div  className="ember-view ember-app scrollPage">
            <div className="menu">
                <ul>
                  <li onClick= {this.GotoHome}><a><img className="orgLogo" src="/assets/images/back.png" width="32px" /></a></li>
                </ul>
              </div>
              <div className="docView" id="app">
			   <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
              </div>
              
              </div>
         );
     }
    }
    export default PrintPDF;