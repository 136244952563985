import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import Loader from "react-js-loader";
class shooter extends Component {
    constructor(props){
        super(props);
        this.state = {
		   isLoggedIn: false,
		   message:'',
		   group:'',
		   destination:'',
		   loader: false,
		   replyall:'',
		   fields: {},
		   render: false
        }
		this.redirectDashboard=this.redirectDashboard.bind(this);
     }

    logout() {
	confirmAlert({
      title: 'Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => localStorage.clear(window.location.href = '/')
        },
        {
          label: 'No'
        }
      ]
    });	
  }
  
  componentDidMount(){	  
  }
  handleChangeName(field, e){         
  let fields = this.state.fields;
  fields[field] = e.target.value;        
  this.setState({fields});
  }
  redirectDashboard(){
  this.props.history.push('/Collection');
  }
  handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let urgenttype = this.urgenttype.value;
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  let userid = session_array.id;
	  let organizationid = session_array.organization_id;
     //param 
     fields["action"]="sendCustomMessage";
	 fields["urgenttype"]=urgenttype;
	 fields["userid"]=userid;
	 fields["organizationid"]=organizationid;
      //message
      if(!fields["custommessage"]){
         formIsValid = false;
         errors["message"] = "failed to send message";
      }
     this.setState({errors: errors});
     return formIsValid;
    }
	
  messageSubmit(e){
      e.preventDefault();
      if(this.handleValidation()){
		this.setState({
		  loader: true
          })  
        this.createMessageApi();
      }else{
         alert("failed to send message");
      }
    }
	
  createMessageApi()
  {
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
				  this.setState({
				  loader: false
				  })
              this.props.history.push('/messages');
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
 
  render() {
if (localStorage.getItem('session')) {	
 return (
    <div id="ember361" className="ember-view ember-app showback"><div className="menu">
        <ul>
          <li className="homeicon"><a onClick= {this.redirectDashboard}>h</a></li>
          <li className="back"><a onClick= {this.redirectDashboard}>M</a></li>
          <li className="titlebar">Messages</li>
          <li className="refresh"><a>⟳</a></li>
          <li className="edit"><a>Edit</a></li>
        </ul>
      </div>
      <div id="application">
        <div className="conversation">
        <ul className="messages">
		  <form acceptCharset="UTF-8"
		  onSubmit= {this.messageSubmit.bind(this)}
		  name="messageForm"
		  id="messageForm">
          <li className="composemessage urgent">
            <span>Urgent Message</span>
            <div className="select-wrapper">
              <select
			  name="urgenttype"
			  ref = {(input)=> this.urgenttype = input}
			  value={this.state.fields["urgenttype"]}
			  onChange={this.handleChangeName.bind(this, "urgenttype")}
			  >
                <option value="shooter" selected="selected">Active Shooter</option>
                <option value="emergency">Emergency</option>
                <option value="intruder">Intruder</option>
              </select>
            </div>
            <span>Custom Message (Optional)</span>
            <textarea
			className="ember-view ember-text-area js-auto-size"
			name="custommessage"
			onChange={this.handleChangeName.bind(this, "custommessage")}
            value={this.state.fields["custommessage"]}></textarea>
            <button className="message-button">Send Message</button>
			{this.state.loader?<Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={100} />:null}
          </li>
        </form>
        </ul>
      </div>
      </div>
      </div>
    );
} else {
 window.location.href = '/';
}	
}
}
export default shooter;