import React, {Component, Fragment} from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization'
import Home from './Home';
import Login from './Login';
import alasql from 'alasql';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import domtoimage from 'dom-to-image';

  class FlowChart extends Component {
  constructor(props){
	super(props);
    this.state={flowChartCards: [], totalArrows: [], chartName: '', persons: [] }
	this.redirectChart=this.redirectChart.bind(this);
}

  componentDidMount (){
	this.getChartDetails();  
    const { cards, objCards, chartid, actions, charts } = this.props;
    const flowChartCards = cards.filter(item => item.chart_id === chartid);
    const totalArrows = [];
    flowChartCards.forEach((item, idx) => {
        if(item.card_type !== 'begin' && item.card_type !== 'end') {
           totalArrows.push({ key: idx, card_type: item.card_type, card_id: item.card_id });
        }
    });
    totalArrows.pop();

    flowChartCards.map((item, idx) => {
      let flowChartActions = [];
      item.actions.map((actionItem, idx) => {
        flowChartActions.push(actions.filter(item => item.id === actionItem)[0]);
      });
      item.actions = flowChartActions;
    });
    this.setState({flowChartCards, totalArrows}, ()=>{console.log('working')});
	
	
  }
  
dataURLtoBlob(dataURL) {
// Decode the dataURL    
const binary = atob(dataURL.split(',')[1]);
// Create 8-bit unsigned array
const array = [];
let i;
for(i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
 }
// Return our Blob object
return new Blob([new Uint8Array(array)], {type: 'image/png'});
 }
 
b64ToUint8Array(b64Image) {
   const img = atob(b64Image.split(',')[1]);
   const img_buffer = [];
   let i = 0;
   while (i < img.length) {
      img_buffer.push(img.charCodeAt(i));
      i++;
   }
   return new Uint8Array(img_buffer);
} 
  
  redirectChart(e){
  e.preventDefault();
  document.getElementById('chart_application').parentNode.style.overflow = 'visible';
  const input = document.getElementById('chart_application');

	
	input.style.top = window.innerHeight + 'px';
  
  
        html2canvas(input, {
        logging: true, letterRendering: 1, allowTaint: false, useCORS: true, dpi: 200, height: 1000})
      .then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
		
        input.style.top = 0;
		/*const u8Image  = this.b64ToUint8Array(imgData);*/
		const imgageData = imgData.replace(/^data:image\/(png|jpg);base64,/, "");
		/*const output = encodeURIComponent(imgData);*/
		/*const newData = imgData.replace(/^data:image\/png/, "data:application/octet-stream");*/
		const chartid = localStorage.getItem("chartid");
		/*canvas.setAttribute('width',900);
        canvas.setAttribute('height',1000);*/
		
		var link = document.createElement("a");
        link.download = 'chart_'+chartid+'.png';
        link.href = imgData;
        /*link.click();*/
	
		
        /*const data = new FormData();
		data.append("chartid", chartid);
		data.append("data", imgageData);
		var xhr = new XMLHttpRequest();
        xhr.open( 'post', 'http://3.89.110.194/pdf.php', true );
		xhr.send(data);*/
        window.location.reload();
      });
  }
  
  componentDidUpdate() {
      const { totalArrows } = this.state;
      if(totalArrows && !totalArrows[0].height) {
          const _totalArrows = totalArrows.map(item => {
              return { ...item, height: this[`wrapperRef${item.key}`].clientHeight/3 - 8}
          });
          this.setState({ totalArrows: _totalArrows });
      }
  }
  
  getChartDetails()
  {
	  let safeguard= localStorage.getItem("safeguard");
      let safeguarddata=JSON.parse(safeguard);
      let charts=safeguarddata.charts;
	  let chartid = localStorage.getItem("chartid");
	  let res = alasql('SELECT * FROM ? where id = ?', [charts,chartid]);
	  let title = res[0].title;
	  this.setState({
        chartName: title
      })
	  
  }
  
  render(){
	  let w = window.screen.width;
      let h = window.screen.height;
	  
    const {closeFlowChartView, title} = this.props;
    const { flowChartCards, totalArrows } = this.state;
	let collectionid = localStorage.getItem("collectionid");
	let chartid = localStorage.getItem("chartid");
    let showIn = false;
    let showOut = false;
    return (
      <div className='flow-chart-container' id="application">
       <div className='overlay-container' id="chart_application">
	   <a className="close">x</a>
	   <div className="overview-controls">
       <h2 className="overview-title">{this.state.chartName}</h2>
  </div>

         <div className='overview-scroller'>
           <div className='overview'>
             <div className='cont-wrapper'>
               <ul className='cards'>
                  {
                    flowChartCards.map((item, idx) =>(
                        <li className='column' key={idx}>
                          <ul ref={node => (item.card_type !== 'begin' && item.card_type !== 'end') ? this[`wrapperRef${idx}`] = node : null } >
                            <li className={item.card_type} data-type={item.card_type} tab={idx}>
                              <div className='content'>
                                {ReactHtmlParser(window.atob(item.content))}
                              </div>
                                {   item.card_type !== '' || showIn ?
								    idx <= 8 ?
                                    <div className="nub left in" id={'ember'+ item.id}>
                                        <div className="node" />
                                    </div>
									: <div className="nub top in" id={'ember'+ item.id}>
                                        <div className="node" />
                                    </div>
									: item.card_type === 'notify' ? showIn = true : null
                                }
                                {   item.card_type !== 'closure' || showOut ?
                                    idx < 7 ?
                                    <div className="nub right out" id={'ember'+ item.id}>
                                        <div className="node" />
                                    </div>
									: <span class="bottom_out"></span>
									: item.card_type === 'notify' ? showOut = true : null
                                }
                            </li>
							
                          </ul>
                      </li>
					
					 
                    ))
                  }
                </ul>
               {totalArrows.map((item, idx) => (								
                   <div
                       key={idx}
                       className="nub-lines"
                       style={{
                           position: "absolute",
                           marginLeft: `${147+(150*idx)}px`,
                           top: "48px",
                           width: "4px",
                           height: `${item.height}px`
                       }}
                   />
                ))}
           </div>
	</div>
         </div>
       </div>
	   
      </div>
    )
  }
};
export default FlowChart;