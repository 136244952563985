import React, { Component, createRef, useState } from 'react';
import { useScreenshot } from 'use-react-screenshot'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import alaSQLSpace from 'alasql';
import domtoimage from 'dom-to-image';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
class incident_management_system extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			flowchart_template: {},
			flowchart_content: '',
			flowchart_status: '',
			ObjOrganization: '',
			image:null
		}
		this.Gotoorg = this.Gotoorg.bind(this);
		this.GotoHelp = this.GotoHelp.bind(this);
		this.GotoHome = this.GotoHome.bind(this);
		this.gotoBack = this.gotoBack.bind(this);
		this.GotoDoc = this.GotoDoc.bind(this);
		this.GotoProfile = this.GotoProfile.bind(this);
		this.redirectChart = this.redirectChart.bind(this);
	}
	componentDidMount() {
		this.getFlowChart();
		this.getOrganizationList();
	}
Gotoorg() {
this.props.redirectToOrg();
}
GotoHelp() {
this.props.redirectToHelp();
}
gotoBack() {
	this.props.closeFlowChartView();
	}
GotoHome() {
this.props.redirectToCollection();
}
GotoDoc() {
this.props.redirectToDoc();
}
GotoProfile() {
	this.props.redirectToProfile();
}
logout() {
	  confirmAlert({
      title: 'Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => localStorage.clear(window.location.href = '/')
        },
        {
          label: 'No'
        }
      ]
    });
	}
	getOrganizationList() {
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		this.setState({
			ObjOrganization: session_array.organization
		})
	}

	redirectChart(e) {
		e.preventDefault();
		document.getElementById('chart_application').parentNode.style.overflow = 'visible';
		const input = document.getElementById('chart_application');
		input.style.top = window.innerHeight + 'px';
		html2canvas(input, {
			logging: true, letterRendering: 1, allowTaint: false, useCORS: true, dpi: 200, height: 1000
		})
			.then((canvas) => {
				const imgData = canvas.toDataURL("image/png");
				input.style.top = 0;
				/*const u8Image  = this.b64ToUint8Array(imgData);*/
				const imgageData = imgData.replace(/^data:image\/(png|jpg);base64,/, "");
				/*const output = encodeURIComponent(imgData);*/
				/*const newData = imgData.replace(/^data:image\/png/, "data:application/octet-stream");*/
				const chartid = localStorage.getItem("chartid");

				let safeguard = localStorage.getItem("safeguard");
				let safeguarddata = JSON.parse(safeguard);
				let charts = safeguarddata.charts;
				let res = alaSQLSpace('SELECT * FROM ? where id = ?', [charts, chartid]);
				let chart_name = res[0].title;
				chart_name = chart_name.toLowerCase();
				chart_name = chart_name.replace(/:\s*/g, " ");
				chart_name = chart_name.replace(/\//g, "");
				chart_name = chart_name.replace(/\s+/g, "_");
				/*canvas.setAttribute('width',900);
				canvas.setAttribute('height',1000);*/
				var link = document.createElement("a");
				/*link.download = 'chart_'+chartid+'.png';*/
				link.download = 'chart_' + chart_name + '.png';
				link.href = imgData;
				link.click();


				/*const data = new FormData();
				data.append("chartid", chartid);
				data.append("data", imgageData);
				var xhr = new XMLHttpRequest();
				xhr.open( 'post', 'http://3.89.110.194/pdf.php', true );
				xhr.send(data);*/
				/*window.location.reload();*/
			});
	}

	getFlowChart() {
		const collectionid = this.props.collectionid;
		const chartid = this.props.chartid;
		const organizationid = this.props.organizationid;
		let flowchart_template = this.state.flowchart_template;
		flowchart_template["action"] = "incident_management_system_flow";
		flowchart_template["collectionid"] = collectionid;
		flowchart_template["chartid"] = chartid;
		flowchart_template["organizationid"] = organizationid;
		fetch(global.flowchart_api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.flowchart_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const html = response.html;
				const title = response.title;
				if (status === "true") {
					this.setState({
						flowchart_content: html,
						title: title,
						flowchart_status: status
					})
				}
			});
	}
	render() {
		let token = localStorage.getItem("session");
		let token_array = JSON.parse(token);
		let user_level_2 = token_array.admin;
		let user_level_1 = token_array.organization_admin;
		let user_super_admin = token_array.super_admin;
		let hide_profile = token_array.hide_profile;
		let hide_logout = token_array.hide_logout;
		const flowstatus = this.state.flowchart_status;
		let flowcontent;

		if (flowstatus == 'true') {
			flowcontent = <ul class="cards">{ReactHtmlParser(this.state.flowchart_content)}<div class="nub-lines lines-right" style={{ 'position': 'absolute', 'left': '147px', 'top': '48px', 'width': '4px', 'height': '48.3594px' }}><div class="rightarrow"></div></div>
				<div class="nub-lines lines-top" style={{ 'position': 'absolute', 'left': '224px', 'top': '30.7%', 'width': '4px', 'height': '2%' }}></div>
				<div class="nub-lines lines-right" style={{ 'position': 'absolute', 'left': '297px', 'top': '5.1%', 'width': '4px', 'height': '33%' }}><div class="rightarrow"></div>
					<div class="lines2"></div></div>
				<div class="nub-lines lines-left" style={{ 'position': 'absolute', 'left': '447px', 'top': '145px', 'width': '4px', 'height': '42%' }}>
					<div class="rightbottomarrow"></div></div>
				<div class="nub-lines lines-right" style={{ 'position': 'absolute', 'left': '447px', 'top': '48px', 'width': '4px', 'height': '14px' }}>
					<div class="rightarrow"></div></div>
				<div class="nub-lines nub-lines lines-right" style={{ 'position': 'absolute', 'left': '597px', 'top': '44px', 'width': '4px', 'height': '15.3%' }}>
					<div class="rightarrow"></div></div>
				<div class="nub-lines lines-right" style={{ 'position': 'absolute', 'left': '597px', 'top': '47.2%', 'width': '4px', 'height': '13.6%' }}>
					<div class="rightarrow"></div></div>
				<div class="nub-lines lines-top" style={{ 'position': 'absolute', 'left': '673px', 'top': '24.63%', 'width': '4px', 'height': '2.5%' }}></div>
				<div class="nub-lines lines-top" style={{ 'position': 'absolute', 'left': '673px', 'top': '43.24%', 'width': '4px', 'height': '2.3%' }}></div>
				<div class="nub-lines lines-right" style={{ 'position': 'absolute', 'left': '62.25%', 'top': '4.1%', 'width': '4px', 'height': '48.8%' }}><div class="rightarrow"></div></div>
			</ul>;
		} else {
			flowcontent = '';
		}

		return (
			<div id="ember361" class="ember-view ember-app">
				<div className="menu">
					<ul>
						<li className="homeicon"><a onClick={this.GotoHome}>h</a></li>
						<li className="back"><a onClick={this.gotoBack}>M</a></li>
						<li className="titlebar">{this.state.ObjOrganization}</li>
						{hide_logout==0?<li className="logout"><a onClick={this.logout}>o<span className="text">Logout</span></a></li>:''}
		                {hide_profile==0?<li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>:''}
						<li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
						<li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
						{user_level_1 == 1 || user_level_2 == 1 ? <li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li> : ''}
					</ul>
				</div>
				<div id="application">
					<div class="overlay-container" id="chart_application">
						<div class="overview-controls">
							<h2 class="overview-title">{this.state.title}</h2>
							<a class="close" data-ember-action="1447" onClick={this.gotoBack}>x</a>
						</div>
						<div class="overview-scroller">
							<div id="ember1885" class="ember-view overview">
								{flowcontent}
							</div>
						</div>
						<div class="clear"></div>
					</div>
				</div>
			</div>
		);
	}
}
export default incident_management_system;
