import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



class PSPDFKIT extends Component {
	state = {file : 'edit.pdf'};

  onDocumentComplete = (pages) => {
    this.setState({ page: 1, pages });
  };

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 });
  };

  handleNext = () => {
    this.setState({ page: this.state.page + 1 });
  };

  renderPagination = (page, pages) => {
    let previousButton = (
      <li className="previous" onClick={this.handlePrevious}>
        <a href="#">
          <i className="fa fa-arrow-left" /> Previous
        </a>
      </li>
    );
    if (page === 1) {
      previousButton = (
        <li className="previous disabled">
          <a href="#">
            <i className="fa fa-arrow-left" /> Previous
          </a>
        </li>
      );
    }
    let nextButton = (
      <li className="next" onClick={this.handleNext}>
        <a href="#">
          Next <i className="fa fa-arrow-right" />
        </a>
      </li>
    );
    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="#">
            Next <i className="fa fa-arrow-right" />
          </a>
        </li>
      );
    }
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  };

  render() {
    let pagination = null;
	const { file } = this.state;
    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages);
    }
    return (
      <div>
        <Document
          file={{url : "https://safeguardmerp.com/pdf/edit.pdf"}}
          onDocumentComplete={this.onDocumentComplete}
          onLoadError={() => {
            console.log(console.error);
          }}
          onLoadProgress={() => {
            console.log("PRGREOSS");
          }}
          page={this.state.page}
        >
          {/* This is what fixes the issue. Try removing the Page component and it would not display PDF */}

          <Page pageNumber={1} />
        </Document>
        {pagination}
      </div>
    );
  }
	}


export default PSPDFKIT;
