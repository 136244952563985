import React, { Component } from 'react';
import queryString from 'query-string'
import AddCollections from './AddCollections'
import EditCollections from './EditCollections'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import {SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
//import arrayMove from 'array-move';
//import 'react-virtualized/styles.css';
import $ from 'jquery';

class Collections extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
	  saveorder_template: {},
      collection_template: {},
	  collection_delete_template: {},
      ObjOrganizations: '',
	  ObjOrganization: '',
      ObjCollections: [],
	  plan_action: '',
	  saveOrderClass: 'btn btn-success planOrderHide'
    }
    this.addCollections=this.addCollections.bind(this);
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.editProfile=this.editProfile.bind(this);
	this.redirectToEditCollections=this.redirectToEditCollections.bind(this);
 }

  addCollections(){
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/AddCollections?organization='+ token);
  }
  editProfile(user_id,organizationid){
  this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
  }
  redirectOrganization(){
  this.props.history.push('/Organization');
  }
  redirectToEditCollections(collectionid,organizationid){
    this.props.history.push('/EditCollections?organization='+organizationid+'&plan='+collectionid);
  }
  redirectToEventAids(collectionid,organizationid)
  {
	  this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid);
  }
  redirectToEventIntroduction(collectionid,organizationid)
  {
	  this.props.history.push('/CollectionSupportingMaterials?organization='+organizationid+'&collectionid='+collectionid);
  }
  
   savePlanOrder(organizationid)
   {
   //console.log(JSON.stringify(this.state.ObjCollections));	   
  let saveorder_template = this.state.saveorder_template;
  saveorder_template["action"] = "savePlanOrder";
  saveorder_template["organization_id"] = organizationid;
  saveorder_template["plan_list"] = JSON.stringify(this.state.ObjCollections);
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.saveorder_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const code = response.code;
       if(status === "true")
       {  
         this.GetCollectionList();
		 this.props.history.push('/collections/Collections?action=order&organization='+organizationid);
       }       
 });
  
  }
  
  redirectDashboard(){
  this.props.history.push('/Collection');
  //window.location.reload();
  }

  logout() {
    if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
componentDidMount() {
  this.GetOrganizationDetail();
  this.GetCollectionList();
}

GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }       
 });
 }
 
 deleteCollectionSubmit(collectionid,organizationid,userid)
{
  if (window.confirm("Are you sure you want to delete this plan")) {
  this.deletePlan(collectionid,organizationid,userid);
}
}

deletePlan(collectionid,organizationid,userid)
{
const query = new URLSearchParams(this.props.location.search);
const token = query.get('organization');  
let collection_delete_template = this.state.collection_delete_template;
collection_delete_template["organizationid"]=organizationid;
collection_delete_template["collectionid"]=collectionid;
collection_delete_template["userid"]=userid;
collection_delete_template["action"]="deleteCollection";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.collection_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	   this.props.history.push('/collections/Collections?action=delete&organization='+ token);
	   this.GetCollectionList();
	   //window.location.reload();
    }       
});
}
onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ObjCollections}) => ({
      ObjCollections: arrayMove(ObjCollections, oldIndex, newIndex),
    }));
	this.setState({
          saveOrderClass: 'btn btn-success'
      })
  };
 GetCollectionList(){
  let collection_template = this.state.collection_template;
  let ObjCollections = this.state.ObjCollections;
  collection_template["action"]="getCollectionList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  collection_template["organizationid"]=organizationid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.collection_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const collection_details = response.collection_details;
       if(status === "true")
       {
        this.setState({
          ObjCollections: response.collection_details
      })
       }       
 });
 
}
      render() {
		  if (localStorage.getItem('session')) {
		  
	  const {ObjCollections} = this.state;	  
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let super_admin = sdata.super_admin;
	  let user_level_2 = sdata.admin;
	  let user_level_1 = sdata.organization_admin;
	  let id = sdata.id;
	  let organization_id = sdata.organization_id;
	  
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const action = query.get('action');
	  const plan_action = this.state.plan_action;
	  let action_message;
	  if(action=='delete')
	  {
		  action_message = <div data-alert="" className="alert alert-success">Collection Successfully Deleted!</div>;
	  }else if(action=='edit'){
		  action_message = <div data-alert="" className="alert alert-success">Collection Successfully Updated!</div>;
	  }else if(action=='create'){
		  action_message = <div data-alert="" className="alert alert-success">Collection Successfully Created!</div>;
	  }else if(action=='order'){
		  action_message = <div data-alert="" className="alert alert-success">Plans order saved successfully!</div>;
	  }else{
		  action_message = '';
	  }
	  if(plan_action=='delete')
	  {
		  action_message = <div data-alert="" className="alert alert-success">Plans successfully deleted!</div>;
	  }
	  
	 const SortableItem = SortableElement(({value,id}) => (
  <li className="moveROW" tabIndex={0} id={id}><div className="leftDIV">{value}</div> 
  <div className="rightDIV">
  {user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEventAids(id,organizationid)}>Tasks</a>:''}&nbsp;
  {user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEventIntroduction(id,organizationid)}>Edit Event Introduction</a>:''}&nbsp;
  {user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEditCollections(id,organizationid)}>Edit</a>:''}&nbsp;
  {user_level_1==1 ?<a className="btn btn-sm btn-default" onClick={()=>this.deleteCollectionSubmit(id,organizationid,user_id)} data-method="delete" rel="nofollow">Delete</a>:''}</div></li>));
	 const SortableList = SortableContainer(({items}) => {
  return (
    <ul className="alternativeROW">
	<li><div className="leftDIV"><strong>Plan Name</strong></div><div className="rightDIV"><strong>Actions</strong></div></li>
      {ObjCollections.map((value,index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value.title} id={value.id} />
      ))}
    </ul>
  );});
	
    return (
      <div className="bodyscroll">
      <div className="navbar navbar-inverse navbar-static-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-left">
            <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
              <li><a data-original-title="" onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a onClick= {this.logout} data-method="delete"  rel="nofollow">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization} >Organization</a> » Plans
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {action_message}
              <h2>
      <span className="pull-right">
	      <a className={this.state.saveOrderClass} onClick={()=>this.savePlanOrder(organizationid)}>Save Plan Order</a>&nbsp;
          {super_admin==1 || user_level_1==1?<a className="btn btn-success" onClick={this.addCollections}>Add Plan</a>:''}
      </span>
      Plans
    </h2>
  <SortableList items={this.state.ObjCollections} onSortEnd={this.onSortEnd} distance={1} />
            </div>
          </div>  
    </div>
</div>
    );
	
  
		  }else{
			  window.location.href = '/';
		  }
  }
}

export default Collections;
