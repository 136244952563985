import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization'
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import alaSQLSpace from 'alasql';
import decode from 'decode-html';
import Base64 from 'base-64'
import FlowChart from './flowchart';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import FlowCard from './flow_cards';
import { render } from 'react-dom';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Incident_management_system from './Charts/incident_management_system';
import Lockdown_secured_perimeter from './Charts/lockdown_secured_perimeter';
import Hazardous_materials from './Charts/hazardous_materials';
import Severe_weather_thunderstorm_and_tornado from './Charts/severe_weather_thunderstorm_and_tornado';
import Evacuation from './Charts/evacuation';
import Reverse_evacuation from './Charts/reverse_evacuation';
import Shelter_in_place from './Charts/shelter_in_place';
import Fire from './Charts/fire';
import Reunification from './Charts/reunification';
import Medical_emergency from './Charts/medical_emergency';
import Earthquake from './Charts/earthquake';
import Winter_storm from './Charts/winter_storm';
import Violence_on_school_grounds from './Charts/violence_on_school_grounds';
import Bomb_threat from './Charts/bomb_threat';
import Civil_disorder from './Charts/civil_disorder';
import Missing_person from './Charts/missing_person';
import Sexual_assault from './Charts/sexual_assault';
import Suicide_attempt_threat from './Charts/suicide_attempt_threat';
import Power_outage from './Charts/power_outage';
import Natural_gas_leak_loss_of_service from './Charts/natural_gas_leak_loss_of_service';
import Flooding_sewer_incident from './Charts/flooding_sewer_incident';
import Communications_loss from './Charts/communications_loss';
import Food_and_water_contamination from './Charts/food_and_water_contamination';
import Pandemic_incident from './Charts/pandemic_incident';
import Transportation_incident from './Charts/transportation_incident';
import Special_rescue from './Charts/special_rescue';
import Structural_collapse from './Charts/structural_collapse';
import Death_of_a_student_staff_member from './Charts/death_of_a_student_staff_member';
import Chemical_attack_incident from './Charts/chemical_attack_incident';
import Biological_attack_incident from './Charts/biological_attack_incident';
import Explosion from './Charts/explosion';
import Nuclear_attack_release from './Charts/nuclear_attack_release';
import Radiological_attack_release from './Charts/radiological_attack_release';

import lock_down from './Charts/lock_down';

class ChartsAction extends Component {
	constructor(props) {
		super(props);
		this.displayData = [];
		this.displayBeginData = [];
		this.ObjDrill = [];
		this.itemRefs = {};
		this.pStyle = {
			background: '#af4600'
		};
		this.state = {
			chart_name: '',
			ObjCollection: [],
			ObjCharts: [],
			ObjDrillLog: [],
			ObjChartsContent: [],
			ObjCards: [],
			ObjBegin: [],
			ObjActions: 0,
			showdata: [],
			ObjOrganization: '',
			value: '',
			showbegindata: [],
			isLoggedIn: false,
			begin_chart: 0,
			clicked: false,
			render: false,
			showFlowChart: false,
			isPaneOpen: false,
			isPaneOpenLeft: false,
			isLogPaneOpen: false,
			isLogPaneOpenLeft: false,
			isClass: 'material',
			isClass2: 'log',
			chart_material_title: '',
			chart_material_content: '',
			breadcrumbs: [],
			action_template: {},
			activeBreadCrumb: [],
			lastFlowCardIndex: 0,
		};
		this.Gotoorg = this.Gotoorg.bind(this);
		this.GotoHelp = this.GotoHelp.bind(this);
		this.GotoHome = this.GotoHome.bind(this);
		this.gotoBack = this.gotoBack.bind(this);
		this.GotoDoc = this.GotoDoc.bind(this);
		this.GotoProfile = this.GotoProfile.bind(this);
		this.GotoSendMessage = this.GotoSendMessage.bind(this);
		this.GotoViewMessage = this.GotoViewMessage.bind(this);
		this.destinationAction = this.destinationAction.bind(this);
		this.handleOverViewClick = this.handleOverViewClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleOverViewClose = this.handleOverViewClose.bind(this);
		this.logout=this.logout.bind(this);
	}

	getChartList() {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		if (localStorage.getItem('session')) {
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let res = alaSQLSpace('SELECT * FROM ? where collection = ?', [charts, collectionid]);

		this.setState({
			ObjCharts: res
		})
		}
	}

	scrollTo(id) {
		this.itemRefs[id].scrollIntoView();
	}
	Gotoorg() {
		this.props.history.push('/Organization');
	}
	GotoHelp() {
		this.props.history.push('/help');
	}

	GotoHome() {
		this.props.history.push('/Collection');
	}
	gotoBack() {
		let collectionid = localStorage.getItem("collectionid");
		this.props.history.push('/Charts?collectionid=' + collectionid);
	}
	deleteNote = (index) => {
		let drill_local_storage = localStorage.getItem("drill_log");
		let drilllog = JSON.parse(drill_local_storage);
		if (index > -1) {
			drilllog.splice(index, 1);
		}
		localStorage.setItem('drill_log', JSON.stringify(drilllog));
		let drill_local_storage_new = localStorage.getItem("drill_log");
		let drilllog_new = JSON.parse(drill_local_storage_new);
		this.setState({ ObjDrillLog: drilllog_new })
	}
	destinationAction = (card_id, destination) => {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		let charts = safeguarddata.charts;
		let previous_cardid = 'ember' + card_id;
		document.getElementById("action_area").innerHTML = "";
		let res = alaSQLSpace('SELECT * FROM ? WHERE id = ?', [cards, destination]);
		let res_charts = alaSQLSpace('SELECT * FROM ? WHERE id = ?', [charts, chartid]);
		if (res.length == 1) {
			let res_action = alaSQLSpace('SELECT * FROM ? WHERE card_id = ?', [actions, res[0].id]);
			this.displayData = [];
			this.displayData.push(<li id={'ember' + res[0].id} className={'ember-view ' + res[0].id + ' ' + res[0].card_type + ' activecard'} data-type={res[0].card_type} data-card={res[0].id} data-chart-title={res_charts[0].title}>
				{ReactHtmlParser(Base64.decode(res[0].content))}
				<p className="card-content-actions"><button onClick={() => this.destinationAction(res[0].id, res_action[0].destination)}>{res_action[0].content != '' ? res_action[0].content : 'Next'}</button></p>
			</li>);
			this.setState({
				showdata: this.displayData,
				begin_chart: 1
			});
		}

	}
	redirectAction(chartid) {
		this.setState({ 
			clicked: true,
			lastFlowCardIndex: 0
		});
		
		let flowIndex = localStorage.getItem('lastIndex');
		localStorage.setItem('chartid', '');
		localStorage.setItem('drill_log', '');
		localStorage.setItem('chartid', chartid);
		this.getChartContent(chartid);
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let charts = safeguarddata.charts;
		let actions = safeguarddata.actions;
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		this.props.history.push('/ChartsAction?collectionid=' + collectionid + '&chartid=' + chartid);
		let res = alaSQLSpace('SELECT cards FROM ? where id = ?', [charts, chartid]);
		this.setState({
			ObjCards: res[0].cards,
			begin_chart: 0,
			ObjDrillLog: []
		})
	}

	GotoDoc() {
		this.props.history.push('/Doc');
	}

	GotoProfile() {
		this.props.history.push('/Profile');
	}

	GotoSendMessage() {
		this.props.history.push('compose');
	}

	GotoViewMessage() {
		this.props.history.push('messages');

	}

	logout() {
	  confirmAlert({
      title: 'Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => localStorage.clear(window.location.href = '/')
        },
        {
          label: 'No'
        }
      ]
    });
	}
	 clearLocalStorage()
		  {
			localStorage.clear();
			window.location.href = '/';
		  }
	componentWillMount() {
		this.getCollectionList();
		this.getChartList();
		this.getCardList();
		this.getBeginArray();
		this.getOrganizationList();
		let chartid = localStorage.getItem("chartid");
		this.getChartContent(chartid);

	}
	componentDidMount() {
		Modal.setAppElement(this.el);
	}

	getOrganizationList() {
		if (localStorage.getItem('session')) {
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		this.setState({
			ObjOrganization: session_array.organization
		})
		}
	}
	getChartContent(chartid) {
		if (localStorage.getItem('session')) {
		let collectionid = localStorage.getItem("collectionid");
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let res = alaSQLSpace('SELECT * FROM ? where collection = ? and id = ?', [charts, collectionid, chartid]);
		this.setState({
			ObjChartsContent: res
		})
		
		}
	}


	getCollectionList() {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		if (localStorage.getItem('session')) {
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let collections = safeguarddata.collections;
		let res = alaSQLSpace('SELECT * FROM ? where id = ?', [collections, collectionid]);
		this.setState({
			ObjCollection: res
		})
		}
	}

	getCardList() {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		if (localStorage.getItem('session')) {
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		let res = alaSQLSpace('SELECT cards FROM ? where id = ?', [charts, chartid]);

		let action_template = this.state.action_template;
		let ObjActions = this.state.ObjActions;
		action_template["action"] = "getActionsExist";
		action_template["chartid"] = chartid;

		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.action_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const count = response.count;
				if (status === "true") {
					this.setState({
						ObjActions: count
					})
				}
			});

		this.setState({
			ObjCards: res[0].cards
		})
		
		}
	}

	getBeginArray(chartid) {
		if (localStorage.getItem('session')) {
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		this.state.ObjCards.map((item, i) => {
			let res = alaSQLSpace('SELECT * FROM ? WHERE id = ? AND chart_id = ? AND card_type = ?', [cards, item, chartid, 'begin']);
			if (res.length == 1) {
				let res_action = alaSQLSpace('SELECT * FROM ? WHERE card_id = ?', [actions, res[0].id]);
				this.displayBeginData = [];
				this.displayBeginData.push(<li id={'ember' + res[0].id} className={'ember-view ' + res[0].id + ' ' + res[0].card_type + ' activecard'} data-type={res[0].card_type} data-card={res[0].id} data-chart-title="Incident Management Systemsss">
					{ReactHtmlParser(Base64.decode(res[0].content))}
					<p className="card-content-actions"><button onClick={() => this.destinationAction(res[0].id, res_action[0].destination)}>{res_action[0].content != '' ? res_action[0].content : 'Next'}</button></p>
				</li>);
			}
		})

		this.setState({
			showbegindata: this.displayBeginData
		});
		}
	}

	handleOverViewClick(lastFlowCardIndex) {

		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let organizationid = session_array.organization_id;
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		
		let res = alaSQLSpace('SELECT * FROM ? where id = ?', [charts, chartid]);
		let chart_name = res[0].default_title;
		
		chart_name = chart_name.toLowerCase();
		chart_name = chart_name.replace(/:\s*/g, " ");
		chart_name = chart_name.replace(/\//g, "");
		chart_name = chart_name.replace(/\s+/g, "_");
		
		this.setState({
			chart_name: chart_name,
			showFlowChart: true,
			lastFlowCardIndex: lastFlowCardIndex,
		});
		//this.props.history.push('/'+chart_name+'?collectionid='+collectionid+'&chartid='+chartid+'&organizationid='+organizationid+'');
	}


	handleOverViewClose() {
		this.setState({ showFlowChart: false });
	}
	hanldeNote(e) {
		e.preventDefault();
		var list = document.getElementById("scroll-div");
		//alert(list.scrollHeight);
		let note = document.getElementById('drill_note').value;
		let date = new Date().getDate(); //Current Date
		let year = new Date().getFullYear(); //Current Year
		let hours = new Date().getHours();
		hours = hours % 12;
		hours = hours ? hours : 12;
		let minute = new Date().getMinutes();
		minute = minute < 10 ? '0' + minute : minute;
		let second = new Date().getSeconds();
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		let now = new Date();
		let thisMonth = months[now.getMonth()];
		let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
		let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
		let current_datetime = dayName + '. ' + thisMonth + ' ' + date + ', ' + year + ' ' + hours + ':' + minute + ':' + second + ' ' + getCurrentAmPm;
		let drill_local_storage = localStorage.getItem("drill_log");
		if (drill_local_storage != '') {
			let drilllog = JSON.parse(drill_local_storage);
			drilllog.push({
				datetime: current_datetime,
				content: note,
				type: 'note',
				source: '0',
				destination: '0'
			});
			localStorage.setItem('drill_log', JSON.stringify(drilllog));
		} else {
			let drill_arr = [];
			drill_arr.push({
				datetime: current_datetime,
				content: note,
				type: 'note',
				source: '0',
				destination: '0'
			});
			localStorage.setItem('drill_log', JSON.stringify(drill_arr));
		}


		let drill_local_storage_new = localStorage.getItem("drill_log");
		let drilllog_new = JSON.parse(drill_local_storage_new);
		this.setState({ ObjDrillLog: drilllog_new })

		document.getElementById('drill_note').value = '';

	}



	addBreadcrumb = addNew => this.setState({ breadcrumbs: [...this.state.breadcrumbs, addNew] });

	resetBreadcrumb = addNew => this.setState({ breadcrumbs: [], activeBreadCrumb: [] });

	resetActiveBreadCrumb = () => this.setState({ activeBreadCrumb: [] });

	hanldeBreadCrumb = item => {
		this.setState({ breadcrumbs: [...this.state.breadcrumbs, item], activeBreadCrumb: [item] }, () => { console.log('---', this.state.activeBreadCrumb) });
	};

	modalOnClickClose = () => {
		var boxWidth = $(".slide-pane__overlay").width();
		boxWidth = boxWidth + "px";

		$(".slide-pane__overlay").animate({ left: boxWidth });
		this.setState({ isLogPaneOpen: false, isClass2: 'log', isClass: 'material' });
	}
	modalOnClickClose1 = () => {
		var boxWidth = $(".slide-pane__overlay").width();
		boxWidth = boxWidth + "px";
		$(".slide-pane__overlay").animate({ left: boxWidth });
		this.setState({ isPaneOpen: false, isClass: 'material', isClass2: 'log' });
	}
	customLogClick = () => {
		let drilllog;
		let drill_local_storage = localStorage.getItem("drill_log");
		if (drill_local_storage != '') {
			drilllog = JSON.parse(drill_local_storage);
		} else {
			drilllog = '';
		}
		this.setState({
			isLogPaneOpen: true,
			isClass2: '',
			isClass: '',
			ObjDrillLog: drilllog
		})
		setInterval(function () {
			$('#scroll-div li').last().addClass('active-li').focus();
		}, 300);
	}



	handleKeyPress(e) {
		const target = e.target;
		$('#scroll-div li').last().attr('tabindex', '');
	}

	render() {
		
		if (localStorage.getItem('session')) {
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let organizationid = session_array.organization_id;

		let token = localStorage.getItem("session");
		let token_array = JSON.parse(token);
		let user_level_2 = token_array.admin;
		let user_level_1 = token_array.organization_admin;
		let hide_profile = token_array.hide_profile;
		let hide_logout = token_array.hide_logout;
		let user_super_admin = token_array.super_admin;
		let isClassName = this.state.isClass;
		let isClassName2 = this.state.isClass2;
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		let charts = safeguarddata.charts;
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		let chart_class = localStorage.getItem("chart_class");
		let className = this.state.clicked ? 'ember-view ember-app ember-application' : 'ember-view ember-app ember-application chartview';


		let date = new Date().getDate(); //Current Date
		let year = new Date().getFullYear(); //Current Year
		let hours = new Date().getHours();
		hours = hours % 12;
		hours = hours ? hours : 12;
		let minute = new Date().getMinutes();
		minute = minute < 10 ? '0' + minute : minute;
		let second = new Date().getSeconds();
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		let now = new Date();
		let thisMonth = months[now.getMonth()];
		let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
		let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
		let current_datetime = dayName + '. ' + thisMonth + ' ' + date + ', ' + year + ' ' + hours + ':' + minute + ':' + second + ' ' + getCurrentAmPm;
		let drilllog;

		let drill_local_storage = localStorage.getItem("drill_log");
		let drill_log;
		if (drill_local_storage != '') {
			drilllog = JSON.parse(drill_local_storage);
		} else {
			drilllog = '';
		}

		let drill_log_content = '';
		let drill = this.state.ObjDrillLog;
		let drill_count = drill.length;
		if (drill_count > 0) {
			const rowLen = this.state.ObjDrillLog.length;
			const last_row_element = parseInt(rowLen) - 1;
			drill_log_content = this.state.ObjDrillLog.map((v, index) => (
				<li key={index} id={index} tabIndex={index == last_row_element ? "1" : ""}>
					<div className="time">{v.datetime}</div>
					<div className="content">{v.type == 'note' ? <a className="delete" onClick={() => this.deleteNote(index)}>x</a> : ''} {v.content}</div>
				</li>
			));
		} else {
			drill_log_content = '';
		}


		let chart_material = this.state.ObjChartsContent.map(v => (
			<h2 key={v.id}>{v.supporting_materials_title}</h2>
		));

		let chart_title = this.state.ObjChartsContent.map(v => (
			<h2 key={v.id}></h2>
		));

		let chart_material_content = this.state.ObjChartsContent.map(v => (
			ReactHtmlParser(v.supporting_materials_content)
		));

		let res_charts = alaSQLSpace('SELECT * FROM ? WHERE id = ?', [charts, chartid]);

		let begin_block = this.state.ObjCards.map((item, i) => {
			let res = alaSQLSpace('SELECT * FROM ? WHERE id = ? AND chart_id = ? AND card_type = ?', [cards, item, chartid, 'begin']);
			if (res.length == 1) {
				let res_action = alaSQLSpace('SELECT * FROM ? WHERE card_id = ?', [actions, res[0].id]);

				if (res_action.length > 0) {
					return <li id={'ember' + res[0].id} className={'ember-view ' + res[0].id + ' ' + res[0].card_type + ' activecard'} data-type={res[0].card_type} data-card={res[0].id} data-chart-title={res_charts[0].title}>
						{ReactHtmlParser(Base64.decode(res[0].content))}
						<p className="card-content-actions"><button onClick={() => this.destinationAction(res[0].id, res_action[0].destination)}>{res_action[0].content != '' ? res_action[0].content : 'Next'}</button></p>
					</li>
				} else {
				}

			}
		})

		let chartList = this.state.ObjCharts.map((v, index) => (
			<li key={index}>{v.id === chartid ? <a style={{ 'backgroundColor': v.color_theme === 'yellow' ? '#f5c200' : v.color_theme === 'orange' ? '#db5700' : v.color_theme === 'maroon' ? '#730e12' : v.color_theme === 'olive_green' ? '#649409' : v.color_theme === 'dark_green' ? '#1d3c0e' : v.color_theme === 'royal_blue' ? '#0d2a7a' : v.color_theme === 'pink' ? '#ff66b3' : v.color_theme === 'grey' ? '#a6a6a6' : v.color_theme === 'black' ? '#000000' : v.color_theme === 'white' ? '#ffffff' : '', 'color': v.color_theme ==='white' ? '#000000':'' }} id={v.id} className="ember-view active" onClick={() => this.redirectAction(v.id)}>{v.title}</a> : <a style={{ 'backgroundColor': v.color_theme === 'yellow' ? '#f5c200' : v.color_theme === 'orange' ? '#db5700' : v.color_theme === 'maroon' ? '#730e12' : v.color_theme === 'olive_green' ? '#649409' : v.color_theme === 'dark_green' ? '#1d3c0e' : v.color_theme === 'royal_blue' ? '#0d2a7a' : v.color_theme === 'pink' ? '#ff66b3' : v.color_theme === 'grey' ? '#a6a6a6' : v.color_theme === 'black' ? '#000000' : v.color_theme === 'white' ? '#ffffff' : '', 'color': v.color_theme ==='white' ? '#000000':'' }} id={v.id} className="ember-view" onClick={() => this.redirectAction(v.id)}>{v.title}</a>}</li>

		));
		let supporting_material_title = this.state.ObjCollection.map(v => (
			<h2>{v.supporting_material_title}</h2>
		));
		let supporting_material_content = this.state.ObjCollection.map(v => (
			ReactHtmlParser(v.supporting_material_content)
		));
		if (localStorage.getItem('session')) {
			if (this.state.showFlowChart) {
				switch (this.state.chart_name) {
					case 'incident_management_system':
						return (
							<Incident_management_system
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'lockdown_secured_perimeter':
						return (
							<Lockdown_secured_perimeter
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'hazardous_materials':
						return (
							<Hazardous_materials
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'severe_weather_thunderstorm_and_tornado':
						return (
							<Severe_weather_thunderstorm_and_tornado
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'evacuation':
						return (
							<Evacuation
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'reverse_evacuation':
						return (
							<Reverse_evacuation
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'shelter_in_place':
						return (
							<Shelter_in_place
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'fire':
						return (
							<Fire
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'reunification':
						return (
							<Reunification
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'medical_emergency':
						return (
							<Medical_emergency
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'earthquake':
						return (
							<Earthquake
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'winter_storm':
						return (
							<Winter_storm
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'violence_on_school_grounds':
						return (
							<Violence_on_school_grounds
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'bomb_threat':
						return (
							<Bomb_threat
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'civil_disorder':
						return (
							<Civil_disorder
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'missing_person':
						return (
							<Missing_person
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'sexual_assault':
						return (
							<Sexual_assault
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'suicide_attempt_threat':
						return (
							<Suicide_attempt_threat
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'power_outage':
						return (
							<Power_outage
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'natural_gas_leak_loss_of_service':
						return (
							<Natural_gas_leak_loss_of_service
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'flooding_sewer_incident':
						return (
							<Flooding_sewer_incident
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'communications_loss':
						return (
							<Communications_loss
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'food_and_water_contamination':
						return (
							<Food_and_water_contamination
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'pandemic_incident':
						return (
							<Pandemic_incident
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'transportation_incident':
						return (
							<Transportation_incident
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'special_rescue':
						return (
							<Special_rescue
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'structural_collapse':
						return (
							<Structural_collapse
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'death_of_a_student_staff_member':
						return (
							<Death_of_a_student_staff_member
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'chemical_attack_incident':
						return (
							<Chemical_attack_incident
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'biological_attack_incident':
						return (
							<Biological_attack_incident
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'explosion':
						return (
							<Explosion
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'nuclear_attack_release':
						return (
							<Nuclear_attack_release
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
					case 'radiological_attack_release':
						return (
							<Radiological_attack_release
								closeFlowChartView={this.handleOverViewClose}
								chartid={chartid}
								collectionid={collectionid}
								organizationid={organizationid}
								redirectToOrg={this.Gotoorg}
								redirectToCollection={this.GotoHome}
								redirectToDoc={this.GotoDoc}
								redirectToHelp={this.GotoHelp}
								redirectToProfile={this.GotoProfile}
							/>
						);
						break;
				}
			} else {
				return (
					<div className={className}>
						<div className="menu">
							<ul>
								<li className="homeicon"><a onClick={this.GotoHome}>h</a></li>
								<li className="back"><a onClick={this.gotoBack}>M</a></li>
								<li className="titlebar">{localStorage.getItem("organization_name")}</li>
								{hide_logout==0?<li className="logout"><a onClick={this.logout}>o<span className="text">Logout</span></a></li>:''}
								{hide_profile==0?<li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>:''}
								<li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
								<li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
								{user_level_1 == 1 || user_level_2 == 1 ? <li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li> : ''}
							</ul>
						</div>
						<div id="application">
							<ul className="book charts">
								{chartList}
							</ul>

							<FlowCard
								charts={charts}
								cards={cards}
								actions={actions}
								chartid={chartid}
								title={res_charts[0].title}
								addBreadcrumb={this.addBreadcrumb}
								resetBreadcrumb={this.resetBreadcrumb}
								activeBreadCrumb={this.state.activeBreadCrumb}
								resetActiveBreadCrumb={this.resetActiveBreadCrumb}

								handleOverViewClick={this.handleOverViewClick}
								ObjActions={this.state.ObjActions}
								breadcrumbs={this.state.breadcrumbs}
								lastFlowCardIndex = {this.state.lastFlowCardIndex}
							/>

							{this.state.ObjActions > 0 ?
								<div className={isClassName}>
									<div className="tab">
										<div ref={ref => this.el = ref}>
											<a onClick={() => this.setState({ isPaneOpen: true, isClass: '', isClass2: '' })}></a>
											<SlidingPane
												className='some-custom-class'
												overlayClassName='some-custom-overlay-class'
												isOpen={this.state.isPaneOpen}
												from='right'
												title={chart_material}
												onRequestClose={() => {
													this.setState({ isPaneOpen: false, isClass: 'material', isClass2: 'log' });
												}}>
												<div className="material-content-block">
													<div className="supporting_material_content">
														{chart_material_content}
													</div>
												</div>
												<div className="material_contentICON" onClick={this.modalOnClickClose1}></div>
											</SlidingPane>
										</div>
									</div>
								</div>
								: ''}

							{this.state.ObjActions > 0 ?
								<div className={isClassName2}>
									<div className="tab">
										<div ref={ref => this.el = ref}>
											<a onClick={this.customLogClick}></a>
											<SlidingPane
												className='some-custom-class'
												overlayClassName='some-custom-overlay-class'
												isOpen={this.state.isLogPaneOpen}
												title={chart_title}
												onRequestClose={() => {
													this.setState({ isLogPaneOpen: false, isClass2: 'log', isClass: 'material' });
												}}>
												<ul id="scroll-div">
													<li>
														<div className="time">{current_datetime}</div>
														<div className="content">Active logging began.</div>
													</li>
													{drill_log_content}
												</ul>

												<form
													onSubmit={this.hanldeNote.bind(this)}
													acceptCharset="UTF-8"
												>
													<input
														id="drill_note"
														className="ember-view ember-text-area note"
														name="drill_note"
														placeholder="Enter your notes here."
														onFocus={this.handleKeyPress}

													/>
													<button>Add Note</button>
												</form>

												<div className="noteICON" onClick={this.modalOnClickClose}></div>
											</SlidingPane>
										</div>
									</div>
								</div>
								: ''}

						</div>
					</div>
				);
			}
		} else {
			window.location.href = '/';
		}
		
	}else{window.location.href = '/';}
	}
}
export default ChartsAction;