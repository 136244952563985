import React, { Component } from 'react';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import alasql from 'alasql';
import TinyMCE from 'react-tinymce';
import { Editor } from '@tinymce/tinymce-react';
import decode from 'decode-html';
import Base64 from 'base-64';
import $ from 'jquery';

class NewTasks extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
      viewtask_template: {},
	  fields: {},
	  collection_template: {},
	  card_template: {},
      ObjOrganizations: '',
      ObjViewTasks: [],
	  ObjCharts: [],
	  ObjCollection : [],
	  ObjCard : [],
	  ObjDestinationCard : [],
	  content: '',
	  chart_template: {},
	  textareaValue : ''
    }
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.redirectEventAids=this.redirectEventAids.bind(this);
	this.redirectPlan=this.redirectPlan.bind(this);
	this.handleEditorChange = this.handleEditorChange.bind(this);
	this.editProfile=this.editProfile.bind(this);
    }

	  redirectOrganization(){
	  this.props.history.push('/Organization');
	  }
	  editProfile(user_id,organizationid)
      {
	 this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
	  redirectDashboard(){
	  this.props.history.push('/Collection');
	  }
	  redirectPlan(e){
	  this.props.history.push('/collections/Collections?organization='+ e);
	  }
	  redirectViewTasks(organizationid,collectionid,chartid){
	  this.props.history.push('/ViewTasks?chartid='+chartid+'&organization='+organizationid+'&collectionid='+collectionid);
	  }
	  redirectEventAids(organizationid,collectionid) {
	  this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid);
	  }
  
  logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }

componentDidMount() {
  this.GetOrganizationDetail();
  this.GetCollectionDetails();
  this.getCardDetails();
  this.getChartList();
}

handleEditorChange(e) {
//console.log(e.target.getContent());
this.setState({
content: e.target.getContent()
})
}

getChartList(){
let chart_template = this.state.chart_template;
let ObjCharts = this.state.ObjCharts;
chart_template["action"]="detailChart";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
chart_template["organizationid"]=organizationid;
chart_template["collectionid"]=collectionid;
chart_template["chartid"]=chartid;

fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.chart_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details=[] ;
	  details.push(response.details);
	 if(status === "true")
	 {
	  this.setState({
		ObjCharts: details
	})
	 }       
});

}

getCardDetails()
{
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');	
const card_id = query.get('card_id');
const chartid = query.get('chartid');
let card_template = this.state.card_template;
let ObjCard = this.state.ObjCard;
card_template["action"]="getAddCardDetails";
card_template["organizationid"]=organizationid;
card_template["collectionid"]=collectionid;
card_template["chartid"]=chartid;

fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.card_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	  const destination_details = response.destination_details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCard: details
	  })
	 }else{
	  this.setState({
		ObjCard: details
	  })	 
	 }
}); 

}

GetCollectionDetails(){
let collection_template = this.state.collection_template;
let ObjCollection = this.state.ObjCollection;
collection_template["action"]="detailCollection";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
collection_template["organizationid"]=organizationid;
collection_template["collectionid"]=collectionid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.collection_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCollection: details
	})
	 }       
});       
}
  GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }else{
	   }
 });
 }
handleOnChange(event) {
    this.setState({
      textareaValue: event.target.value
    })
  }
handleValidation(){
let fields = this.state.fields;
let errors = {};
let formIsValid = true;
let session = localStorage.getItem("session");
let session_array=JSON.parse(session);
let userid = session_array.id;
const query = new URLSearchParams(this.props.location.search);
const organization = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
const card_id = this.state.ObjCard.id;
const chart_content = this.state.content;
let editor_content =  $('.tox-edit-area__iframe').contents().find('#tinymce').html();
if(editor_content=='<p><br data-mce-bogus="1"></p>')
{
editor_content = '';	
}else{
editor_content = editor_content;	
}
editor_content = editor_content.replace(/&quot;/g, '');
editor_content = editor_content.replace(/\\/g, '');
fields["action"]="updateAddCardContent";
fields["organizationid"]=organization;
fields["collectionid"]=collectionid;
fields["chartid"]=chartid;
fields["card_id"]=card_id;
fields["content"]=editor_content;
fields["userid"]=userid;
if(chartid==''){
 formIsValid = false;
}
this.setState({errors: errors});
return formIsValid;
}

cardSubmit(e){
e.preventDefault();
if(this.handleValidation()){
//console.log(this.state.fields);	
this.updateCardContentApi();
}else{
 alert("Mandatory Field Emplty!");
}
}

updateCardContentApi()
{
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const collectionid = query.get('collectionid');
	  const chartid = query.get('chartid');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
			  window.location.reload();
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
  render() {
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
	  
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization'); 
	const collectionid = query.get('collectionid');
	const chartid = query.get('chartid');
	
	
	let title = this.state.ObjCharts.map(v => (
	  v.title
    ));
	
	let action_title = this.state.ObjCard.action_content;

	
	let content = Base64.encode(this.state.ObjCard.content);
	if (localStorage.getItem('session')) {
	
    return (
	<div className="bodyscroll">		
	<div className="navbar navbar-inverse navbar-static-top" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-left">
                <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
              <li className="active" onClick={this.redirectOrganization}><a>Admin</a></li>
              <li className=""><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a rel="nofollow" onClick= {this.logout} title="Log Out">Log Out</a></li>
              </ul>
            </div>
          </div>
        </div>
	
	<div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a onClick={this.redirectOrganization}>Admin</a> » <a data-original-title="" onClick={()=>this.redirectEventAids(organizationid,collectionid)}>{this.state.ObjCollection.title}</a> » {title}
          </div>
          </div>
          <div className="row">
          <div className="col-xs-12">
          <h1 style={{'paddingLeft' : '10px'}}>Edit Tasks</h1>
              
		<div className="container">
        <form
		acceptCharset="UTF-8"
		className="edit_card"
		id="edit_card"
		onSubmit= {this.cardSubmit.bind(this)}>
        <div className="row">
        <div className="col-md-6">
		<div className="form-group card_content_editor">
		<Editor
		initialValue={decode(Base64.decode(content.toString()))}
        init={{
			height: 400,
			menubar:true,
            statusbar: false,
			relative_urls : true,
            document_base_url : 'https://safeguardmerp.com/',
			link_assume_external_targets: true,
			link_class_list: [
    {title: 'None', value: ''},
    {title: 'External Link', value: 'ext_link'},
    {title: 'Internal Support Link', value: 'int_sup_link'},
    {title: 'Internal Marketing Link', value: 'int_mark_link'},
    {title: 'Other Internal Link', value: 'int_other_link'}
  ],
        plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks advcode fullscreen',
			'insertdatetime media table powerpaste hr code wordcount contextmenu'
                 ],
		      contextmenu: "link image inserttable | cell row column deletetable | undo redo | copy | paste | cut",
			  toolbar: 'undo redo | formatselect | insert | styleselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code',
			  powerpaste_allow_local_images: true,
			  powerpaste_word_import: 'prompt',
			  powerpaste_html_import: 'prompt'
        }}
        onChange={this.handleEditorChange}
      />
		</div>
        <br/>
        </div>
        <div className="col-md-6">
        <label>Card preview</label>
		<div className="card-preview-container">
        
        <div className={'card-preview ' + this.state.ObjCard.card_type_lower}>
		
        <Editor
					initialValue={this.state.ObjCard.content_full}
					disabled = {true}
					init={{
						branding: false,
						menubar: false,
						toolbar: false,
						plugins: 'autoresize',
						readonly: true,
						statusbar:false
						}}
                    />
		
		
        </div>
        </div>
        
        </div>
        </div>
		<div className="form-group">
		<input className="btn btn-warning" name="apply" type="submit" value="Apply" />&nbsp;
		<input className="btn btn-success" name="save" type="submit" value="Save Tasks" />&nbsp;
		<a className="btn btn-default" onClick={()=>this.redirectEventAids(organizationid,collectionid)}>Cancel</a>
		</div>
		</form>
		</div>
		</div>
        </div>
    </div>
	</div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default NewTasks;