import React, { Component } from 'react';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import 'react-virtualized/styles.css';
import $ from 'jquery';
class EventAids extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
      eventaids_template: {},
	  collection_delete_template: {},
	  collection_template: {},
      ObjOrganizations: '',
      ObjEventAids: [],
	  ObjCollection: [],
	  ObjActions:0,
	  plan_action: '',
	  saveorder_template: {},
	  saveOrderClass: 'btn btn-success planOrderHide'
    }
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.redirectPlan=this.redirectPlan.bind(this);
	this.GotoaddEventAids=this.GotoaddEventAids.bind(this);
	this.editEventAids=this.editEventAids.bind(this);
	this.viewTask=this.viewTask.bind(this);
	this.addTask=this.addTask.bind(this);
	this.supportingMaterial=this.supportingMaterial.bind(this);
	this.editProfile=this.editProfile.bind(this);
 }
  
  GotoaddEventAids(){
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  const collectionid = query.get('collectionid');
  this.props.history.push('/AddEventAids?organization='+organizationid+'&collectionid='+collectionid);
  }
  editEventAids(chartid,organizationid,collectionid)
  {
  this.props.history.push('/EditEventAids?organization='+organizationid+'&collectionid='+collectionid+'&chartid='+chartid);
  }
  editProfile(user_id,organizationid){
  this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
  }
  viewTask(chartid,organizationid,collectionid)
  {
  this.props.history.push('/ViewTasks?chartid='+chartid+'&organization='+organizationid+'&collectionid='+collectionid);
  }
  addTask(chartid,organizationid,collectionid)
  {
  this.props.history.push('/NewTasks?chartid='+chartid+'&organization='+organizationid+'&collectionid='+collectionid);
  }
  supportingMaterial(organizationid,collectionid,chartid)
  {
  this.props.history.push('/SupportingMaterials?organization='+organizationid+'&collectionid='+collectionid+'&chartid='+chartid);
  }
  redirectOrganization(){
  this.props.history.push('/Organization');
  }
  redirectDashboard(){
  this.props.history.push('/Collection');
  }
  redirectPlan(e)
  {
	  this.props.history.push('/collections/Collections?organization='+ e);
  }

  logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
componentDidMount() {
  this.GetOrganizationDetail();
  this.GetEventAidsList();
  this.GetCollectionDetails();
}

GetCollectionDetails(){
let collection_template = this.state.collection_template;
let ObjCollection = this.state.ObjCollection;
collection_template["action"]="detailCollection";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
collection_template["organizationid"]=organizationid;
collection_template["collectionid"]=collectionid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.collection_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCollection: details
	})
	 }       
});       
}
   
GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }       
 });
 }
 
 deleteEventAidsSubmit(chartid,organizationid,userid)
{	
  if (window.confirm("Are you sure you want to delete this chart?")) {
  this.deleteEventAids(chartid,organizationid,userid);
}
}

deleteEventAids(chartid,organizationid,userid)
{
const query = new URLSearchParams(this.props.location.search);
const collectionid = query.get('collectionid');
let collection_delete_template = this.state.collection_delete_template;
collection_delete_template["organizationid"]=organizationid;
collection_delete_template["chartid"]=chartid;
collection_delete_template["userid"]=userid;
collection_delete_template["action"]="deleteEventAids";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.collection_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {	
	   this.props.history.push('/EventAids?action=delete&organization='+organizationid+'&collectionid='+collectionid);
	   this.GetEventAidsList();
	   //window.location.reload();
    }else{
		alert('something went wrong,please try again!');
	}
});
}
 saveTabOrder(organizationid,collectionid)
   {
   //console.log(JSON.stringify(this.state.ObjEventAids));	   
  let saveorder_template = this.state.saveorder_template;
  saveorder_template["action"] = "saveChartOrder";
  saveorder_template["organizationid"] = organizationid;
  saveorder_template["collectionid"] = collectionid;
  saveorder_template["chart_list"] = JSON.stringify(this.state.ObjEventAids);
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.saveorder_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const code = response.code;
       if(status === "true")
       {  
         this.GetEventAidsList();
		 this.props.history.push('/EventAids?action=order&organization='+organizationid+'&collectionid='+collectionid);
       }       
 });
  
  }
  
 GetEventAidsList(){
  let eventaids_template = this.state.eventaids_template;
  let ObjEventAids = this.state.ObjEventAids;
  eventaids_template["action"]="getEventAidsList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  const collectionid = query.get('collectionid');
  eventaids_template["organizationid"]=organizationid;
  eventaids_template["collectionid"]=collectionid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.eventaids_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const eventaids_details = response.eventaids_details;
       if(status === "true")
       {
        this.setState({
          ObjEventAids: eventaids_details
      })
       }       
 });
}
onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ObjEventAids}) => ({
      ObjEventAids: arrayMove(ObjEventAids, oldIndex, newIndex),
    }));
	this.setState({
          saveOrderClass: 'btn btn-success'
      })
  };
    render() {
		if (localStorage.getItem('session')) {
		
	let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let super_admin = sdata.super_admin;
	  let organization_id = sdata.organization_id;
	  let user_level_2 = sdata.admin;
	  let user_level_1 = sdata.organization_admin;
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const collectionid = query.get('collectionid');
	const action = query.get('action');
	let action_message
	  if(action=='delete')
	  {
		  action_message = <div data-alert="" className="alert alert-success">Chart Successfully Deleted!</div>;
	  }else if(action=='create'){
		  action_message = <div data-alert="" className="alert alert-success">Chart Successfully Addedd!</div>;
	  }else if(action=='edit'){
		  action_message = <div data-alert="" className="alert alert-success">Chart Successfully Edited!</div>;
	  }else if(action=='order'){
		  action_message = <div data-alert="" className="alert alert-success">Chart order saved successfully!</div>;
	  }else{
	  }
	const SortableItem = SortableElement(({value,id,action_count,userid}) => (
  <li className="moveROW" tabIndex={0} id={id}><div className="leftDIV">{value}</div> 
  <div className="rightDIV">
  {action_count>0?<a className="btn btn-sm btn-default" onClick={()=>this.supportingMaterial(organizationid,collectionid,id)}>Edit Event Aid</a>:''}&nbsp;
  {action_count>0?<a className="btn btn-sm btn-default" onClick={()=>this.viewTask(id,organizationid,collectionid)}>View Tasks</a>:<a className="btn btn-sm btn-default" onClick={()=>this.addTask(id,organizationid,collectionid)}>Edit Tasks</a>}&nbsp;
  <a className="btn btn-sm btn-default" onClick={()=>this.editEventAids(id,organizationid,collectionid)}>Edit</a>&nbsp;
  {super_admin==1 || user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.deleteEventAidsSubmit(id,organizationid,user_id)} data-method="delete" rel="nofollow">Delete</a>:''}</div></li>));
	
	const SortableList = SortableContainer(({items}) => {
  return (
    <ul className="alternativeROW">
	<li><div className="leftDIV"><strong>Page Name</strong></div><div className="rightDIV"><strong>Actions</strong></div></li>
      {this.state.ObjEventAids.map((value,index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value.title} id={value.id} action_count={value.action_count} userid={value.user_id} />
      ))}
    </ul>
  );});
	
	
		
    return (
      <div className="bodyscroll">
      <div className="navbar navbar-inverse navbar-static-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-left">
            <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
                <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
              <li><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a onClick={this.logout} data-method="delete" rel="nofollow">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a onClick={this.redirectOrganization} >Admin</a> » <a onClick={this.redirectOrganization} >Organization</a> » <a onClick={()=>this.redirectPlan(organizationid)} >Plans</a> » {this.state.ObjCollection.title}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
             {action_message}
              <h2>
      <span className="pull-right">
	  <a className={this.state.saveOrderClass} onClick={()=>this.saveTabOrder(organizationid,collectionid)}>Save Chart Order</a>&nbsp;
      {super_admin==1 || user_level_1==1?<a className="btn btn-success" onClick={this.GotoaddEventAids}>Add Tab</a>:''}
      </span>
     {this.state.ObjCollection.title}
    </h2>
    
  <SortableList items={this.state.ObjEventAids} onSortEnd={this.onSortEnd} distance={1} />

</div>
          </div>  
    </div>
</div>

     
    );
	
	
  
		}else{
			window.location.href = '/';
		}
  }
}

export default EventAids;
