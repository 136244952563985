import React,{Component} from 'react';
import Home from './Home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, NavLink, Switch, Link, Route } from 'react-router-dom';
import Collection from './Collection';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Login extends Component{

    constructor(){
        super();
        this.state = {
          useremail:'',
          passwordCheck:true,
          domainName:'',
          azureConnect:false,
          userpassword:'',
          isUser:'abc',
          isDialogVisible: false,
          checkDomainTemplate : {},
		      messages_template : {},
		      email_template : {},
          tfa_template : {},
          azure_template : {},
		      ObjEmail : [],
          collections:'',
          email:'',
          id:'',
          school:'',
		      loader: '',
		      loader_class: '',
          fields: {},
          errors: {}
        }
     }

      componentWillMount() {

       let query = new URLSearchParams(this.props.location.search);
       let username = query.get('username');
		   let password = query.get('password');
       let azureStatus = query.get('azureStatus');

		 let fields = this.state.fields;
     fields["email"] = username;
		 if (localStorage.getItem('session')) {
		 }else{
		  if((username!='' && username!=null) && (password!='' && password!=null) && azureStatus==null)
				 {
					 fields["action"]="userLogin";
					 fields["email"]=username;
					 fields["password"]=password;
					 fields["device_type"]='';
	         fields["device_token"]='';
					 this.LoginApi();
				 }else if(azureStatus!='' && azureStatus!=null){
          let userdetail = query.get('userdetail');
          if(userdetail!='' && userdetail!=null)
					 {
					 userdetail = atob(userdetail);
					 let azure_query = new URLSearchParams(userdetail);
					 let azure_email = azure_query.get('username');
					 let azure_password = azure_query.get('password');
					 let domain = azure_query.get('domain');
					 let firstname = azure_query.get('firstname');
					 let lastname = azure_query.get('lastname');
					 let group = azure_query.get('group');
           if(group!='invalid' && group!='')
           {
            fields["action"]="userAzureLogin";
						fields["firstname"]=firstname;
						fields["lastname"]=lastname;
						fields["email"]=azure_email;
						fields["password"]=azure_password;
						fields["domain"]=domain;
						fields["device_type"]='';
						fields["device_token"]='';
						fields["group"]=group;
						this.LoginAzureApi();
           }else{
              toast.error("Azure group object ID is not found!please check your Azure AD configuration!", {
                position: toast.POSITION.TOP_CENTER
              });
           }
					 }else{
            toast.error("Something went wrong,please try again!", {
              position: toast.POSITION.TOP_CENTER
            });
					 } 

         }else{

         }	 
		 }
				 
		  }
     handleValidation(){
      let emailid = document.getElementById('email').value;
      let passcode = document.getElementById('password').value;
      // if(emailid!='' && passcode!='')
      // {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        fields["action"]="userLogin";
        //Email
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Cannot be empty";
        }
      
        // if(typeof fields["email"] !== ""){
        //    let lastAtPos = fields["email"].lastIndexOf('@');
        //    let lastDotPos = fields["email"].lastIndexOf('.');
      
        //    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        //       formIsValid = false;
        //       errors["email"] = "Email is not valid";
        //     }
        // }  

        //password
        if(!fields["password"]){
            formIsValid = false;
            errors["password"] = "Cannot be empty";
         }
		   fields["device_type"]='';
	     fields["device_token"]='';
       this.setState({errors: errors});
       return formIsValid;
      // }else{
      //   toast.error("Mandetory field(s) missing", {
      //     position: toast.POSITION.TOP_CENTER
      //   });
      // }

       
      }
      
      contactSubmit(e){
        e.preventDefault();
        let azureConnect = this.state.azureConnect;
		    let domainName = this.state.domainName;
		    this.setState({
        loader_class: 'loader'
        })
         
        if(azureConnect==false)
		    {
          if(this.handleValidation()){
            this.LoginApi();
           }else{
          this.setState({
              loader_class: ''
              })
          toast.error("Sorry, Wrong Username or Password", {
         position: toast.POSITION.TOP_CENTER
       });
           }
        }else{
          if(domainName=='pasd')
            {
              window.location.replace("https://safeguardapi.safeguardmerp.com/saml");
            }else if(domainName=='acsd1')
            {
              window.location.replace("https://safeguardapi.safeguardmerp.com/acsd1");
            }else{
              this.setState({
                loader_class: ''
                })
                toast.error("This email domain is not active for Azure AD!", {
                  position: toast.POSITION.TOP_CENTER
                });
            }

        }

        
      }
      

      handleChangePassword(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
      }
      handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        
        var text = e.target.value;
		let result = text.includes("@");
		
		if(result==true)
		{
			const myArray = text.split("@");
			let domain_string = myArray[1];
			
			let domain_confirm = domain_string.includes(".");
			
			if(domain_confirm==true)
		    {
				const domainArray = domain_string.split(".");
				let domain_name = domainArray[0];
				
				let checkDomainTemplate = this.state.checkDomainTemplate;
				checkDomainTemplate["action"]="checkDomainStatus";
			  checkDomainTemplate["domain"]=domain_name;
        //console.log(JSON.stringify(checkDomainTemplate));
				fetch(global.api_url,
         {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(checkDomainTemplate)
     })
      .then((response) => response.json())
      .then((response) => { 
			 console.log(response);
        const status=response.status;
		    const code=response.code;
       if(code === "200")
       {
		   this.setState({
        passwordCheck: false,
				azureConnect : true,
				domainName : domain_name
                })
		   }else if(code === "400"){
			  this.setState({
        passwordCheck: true,
				azureConnect : false,
				domainName : domain_name
                }) 
			   }else{
		  
	   }
 });
				
			}else{
				this.setState({
                passwordCheck: true,
				azureConnect : false
                }) 
			}
		}
        this.setState({fields});
      }
	  forgotPassword()
	  {
		  let email = prompt("Enter your email to have your password reset and instructions on setting a new one sent to your email:", "");
		  if (email != null) {
		var data={
                "action": "passwordEmail",
                "email": email     
                }
		  fetch(global.api_url,
         {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(data)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		const text=response.text;
		const code=response.code;
       if(code === "200")
       {
		//alert('Password reset link has been sent to your email address');
		toast.success("Password reset link has been sent to your email address", {
      position: toast.POSITION.TOP_CENTER
    });
		window.location.reload();
       }else if(code === "400"){
		 //alert('Something went wrong, please try again!');
		 toast.error("Something went wrong, please try again!", {
      position: toast.POSITION.TOP_CENTER
    });
	   }else{
		   //alert(text);
		   toast.error(text, {
      position: toast.POSITION.TOP_CENTER
    });
		   
	   }
 });
			
			
		  }
	  }
    getMessageList(){
  let messages_template = this.state.messages_template;
  let ObjMessages = this.state.ObjMessages;
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organization_id = session_array.organization_id;

  messages_template["action"]="getMessageList";
  messages_template["organization_id"]=organization_id;
  messages_template["group_id"]=session_array.group_id;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.messages_template)
     })
      .then((response) => response.json())
      .then((response) => {
        const status=response.status;
        const message_list = response.message_list;
       if(status === "true")
       {
		localStorage.setItem('safeguard_messages', JSON.stringify(message_list));  
       }else{
		   localStorage.setItem('safeguard_messages', '');
	   }
 });
    }
      LoginApi()
      {
        fetch(global.api_url,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'                 
            },
            body: JSON.stringify(this.state.fields)
        })
        .then((response) => response.json())
        .then((response) => {
             const status=response.status;
			 const status_message = response.message;
			 const code = response.code;
             if(status === "true")
             {
				this.setState({
                loader_class: ''
                }) 
				localStorage.setItem('session', JSON.stringify(response.user_details));
				localStorage.setItem('safeguard', JSON.stringify(response.safeguard));
				localStorage.setItem('organization_details', JSON.stringify(response.organization_details));
				localStorage.setItem('drill_log', '');
				localStorage.setItem('lastIndex', '');
				localStorage.setItem('preIndex', '');
				localStorage.setItem('organization_name', response.organization_name);
                this.getMessageList();
                this.props.history.push('/Collection?status=from_login');
             }
             else{
				this.setState({
                loader_class: ''
                }) 
				toast.error(status_message, {
      position: toast.POSITION.TOP_CENTER,
	  autoClose: 4000
    });
				
				
				
             }
        });
      }
      LoginAzureApi()
      {
		  console.log(JSON.stringify(this.state.fields));
        fetch(global.api_url,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'                 
            },
            body: JSON.stringify(this.state.fields)
        })
        .then((response) => response.json())
        .then((response) => {
			 
       const status=response.status;
			 const status_message = response.message;
			 const user_details = response.user_details;
			 const organization_details = response.organization_details;
			 let email = user_details.email;
			 if(status === "true")
			 {
        this.setState({
          loader_class: ''
          }) 
          localStorage.setItem('session', JSON.stringify(response.user_details));
          localStorage.setItem('safeguard', JSON.stringify(response.safeguard));
          localStorage.setItem('organization_details', JSON.stringify(response.organization_details));
          localStorage.setItem('drill_log', '');
          localStorage.setItem('lastIndex', '');
          localStorage.setItem('preIndex', '');
          localStorage.setItem('organization_name', response.organization_name);
          this.getMessageList();
          this.props.history.push('/Collection?status=from_login');
			//  if(organization_details.tfa==1 && organization_details.web_app==1 && user_details.tfa==1 && (user_details.group_name=='PREPAdmin' || user_details.group_name=='PREPIncidentCommander' || user_details.group_name=='PREPUsers'))
			//  {	 
			//   let tfa_template = this.state.tfa_template;
			//   tfa_template["action"]="tfaCredentialStatus";
			//   tfa_template["email"]=email;
			  
			//   fetch(global.api_url,
      //         {
      //        method: 'POST',
      //        headers: {
      //            Accept: 'application/json',
      //            'Content-Type': 'application/json'
      //        },
      //        body: JSON.stringify(this.state.tfa_template)
      //        })
      //         .then((response) => response.json())
      //         .then((response) => {
      //       let status=response.status;
			// let verification_mobile=response.mobile;
			// let verification_email=response.email;
			// verification_email = Base64.encode(verification_email);
			// verification_mobile = Base64.encode(verification_mobile);
			// let userid=response.userid;
			// userid = Base64.encode(userid);
      //      if(status === "true")
      //      {
			//    this.props.history.push('/Verification?email='+verification_email+'&mobile='+verification_mobile+'&userid='+userid);
		  //   }else{
			// 	this.props.history.push('/Permission?email='+email);
			//    }
			// 	 });
			//  }else{
			// 	 if(status === "true")
      //        {
      //                         this.setState({
      //                         loader_class: ''
      //                         }) 
      //                         localStorage.setItem('session', JSON.stringify(response.user_details));
      //                         localStorage.setItem('organization_details', JSON.stringify(response.organization_details));
      //                         localStorage.setItem('drill_log', '');
      //                         localStorage.setItem('cardid', '');
      //                         localStorage.setItem('activeCardIndex', '');
      //                         localStorage.setItem('organization_name', response.organization_name);
      //                         this.getMessageList();
			// 	                      this.props.history.push('/Collection');
                
      //        }
      //        else{
			// 	this.setState({
      //           loader_class: ''
      //           }) 
      //           alert(status_message);
      //        }
			//  }
			}else{
				toast.error(status_message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 4000
				  });
			}
			 
        });
      }
    render(){
      let passwordCheck = this.state.passwordCheck;  
		if (localStorage.getItem('session')) {   
		window.location.href = '/Collection';
		}else {
        return(
            <div id="ember361"  className="ember-view ember-app"><div  className="login">
            <h2>Mobile Emergency Response Plan</h2>
			      <ToastContainer closeButton={false} /> 
            <form name="contactform" onSubmit={this.contactSubmit.bind(this)}>
              <input 
               id="email" 
                className="ember-view ember-text-field logtxt" 
               placeholder="Email Address"
               autoCapitalize="off"
               autoCorrect="off"
               type="text"
               onChange={this.handleChange.bind(this, "email")}
               value={this.state.fields["email"] || ''}
              />
              {passwordCheck? <input
                id="password" 
                 className="ember-view ember-text-field passwordtxt"
                placeholder="Password" 
                type="password"
                onChange={this.handleChangePassword.bind(this, "password")}
                value={this.state.fields["password"] || ''}
                /> : null}

              <button id="login">Sign In</button>
			        <div className={this.state.loader_class}></div>
            </form>          
            {passwordCheck ? <a className="forgot" onClick= {this.forgotPassword}>Forgot your password?</a> : null }
          </div>
          <div  className="watermark"></div>
          
          </div>  
        );
}	
    }
}

export default Login;