import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import axios from 'axios';

class EditFile extends Component {
    constructor(props){
        super(props);
        this.state = {
        name: '',
        ObjOrganizations: [],
        ObjFolder: [],
		ObjSubFolder: [],
		ObjFile: [],
        fields: {},
		optionsState: 0,
		optionsSubState: 0,
        organization_template: {},
		file_attachment_attachment: null,
		file_attachment_file_folder_id: '',
		file_attachment_sub_folder_id:'',
        folder_template: {},
		sub_folder_template: {},
		file_template: {},
        error: {},
        selectedFile: null,
        pathname:null,

        }
        this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.redirectAttachment=this.redirectAttachment.bind(this);
     }
     
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 redirectAttachment()
	  {
		  const query = new URLSearchParams(this.props.location.search);
          const organizationid = query.get('organization');
		  this.props.history.push('/Attachment?organization='+organizationid);
	  }
     handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  const fileid = query.get('folderid');
	  //let file_attachment_cache_on_app_isChecked = document.getElementById('file_attachment_cache_on_app').checked;
	  let selected_folder = document.getElementById("file_attachment_file_folder_id");
	  //let selected_folderid = selected_folder.options[selected_folder.selectedIndex].value;
	  fields["file_attachment_cache_on_app"] = 'false';
	  //fields["file_attachment_file_folder_id"] = selected_folderid;
	  
	  if (this.refs.file_folderid) {
       fields["file_attachment_file_folder_id"]=this.refs.file_folderid.value;
      }
	  
	  if (this.refs.file_subfolderid) {
       fields["file_attachment_sub_folder_id"]=this.refs.file_subfolderid.value;
      }
	  
	  if(this.refs.file_folderid.value=='')
	  {
	   fields["file_attachment_file_folder_id"] = 'false'
	  }else{
		  fields["file_attachment_file_folder_id"]=this.refs.file_folderid.value;
	  }
	  
	  if(this.refs.file_subfolderid.value=='')
	  {
	   fields["file_attachment_sub_folder_id"] = 'false'
	  }else{
		  fields["file_attachment_sub_folder_id"]=this.refs.file_subfolderid.value;
	  }
     //action 
     fields["action"]="editFile";
     fields["organizationid"]=token;
	 fields["fileid"]=fileid;
    this.setState({errors: errors});
     return formIsValid;
    }
    redirectOrganization(){
      this.props.history.push('/Organization');
      }

    GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {

            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }

      GetFolderList(){
      let folder_template = this.state.folder_template;
      let ObjFolder = this.state.ObjFolder;
      folder_template["action"]="getAddedFolderList";
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
      folder_template["organizationid"]=organizationid;
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.folder_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const folder_details = response.list;
           if(status === "true")
           {
            this.setState({
              ObjFolder: folder_details
          })
           }       
     });
    }
	
	  GetFileDetails(){
      let file_template = this.state.file_template;
      let ObjFile = this.state.ObjFile;
      file_template["action"]="detailFile";
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const folderid = query.get('folderid');
      file_template["organizationid"]=organizationid; 
	  file_template["folderid"]=folderid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.file_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const file_details = response.file_details;
           if(status === "true")
           {
            this.setState({
              ObjFile: response.file_details,
			  optionsState: response.file_details.file_folder_id,
			  optionsSubState: response.file_details.sub_folder_id
          })
			
			this.getSubfolderOnload();
			
           }       
     });
 }
 
    fileSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
		//console.log(this.state.fields);
		this.editFileApi();
	}
	}
    
    editFileApi()
    {
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
              this.props.history.push('/Attachment?success=true&organization='+ token);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }

logout() {
if (window.confirm("Are you sure you want to log out?")) {
localStorage.clear();
window.location.href = '/';
}
}
  componentDidMount() {
    this.GetOrganizationDetail();
    this.GetFolderList();
	this.GetFileDetails();
  }
  handleChangeSubFolderName(field, e)
	{
		let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
	}
  getSubfolderOnload()
  {
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const selected_foldlerid = this.state.optionsState;
	  let sub_folder_template = this.state.sub_folder_template;
	  let ObjSubFolder = this.state.ObjSubFolder;
	  sub_folder_template["action"]="detailSubFolder"; 
	  sub_folder_template["folderid"]=selected_foldlerid;
	  sub_folder_template["organizationid"]=organizationid;
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.sub_folder_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const subfolder_details = response.subfolder_details;
			console.log(JSON.stringify(subfolder_details));
           if(status === "true")
           {
            this.setState({
              ObjSubFolder: response.subfolder_details
          })
           }else{
			   this.setState({
              ObjSubFolder: []
          })
		   }
  });
  }
  
  handleChangeName(field, e){
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  let sub_folder_template = this.state.sub_folder_template;
	  let ObjSubFolder = this.state.ObjSubFolder;
	  sub_folder_template["action"]="detailSubFolder"; 
	  sub_folder_template["folderid"]=e.target.value;
	  sub_folder_template["organizationid"]=organizationid;
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.sub_folder_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const subfolder_details = response.subfolder_details;
			console.log(JSON.stringify(subfolder_details));
           if(status === "true")
           {
            this.setState({
              ObjSubFolder: response.subfolder_details
          })
           }else{
			   this.setState({
              ObjSubFolder: []
          })
		   }
     });

    }
  render() {
	let selected_file_folderid = this.state.optionsState;
	let selected_sub_file_folderid = this.state.optionsSubState;
	let folderList = this.state.ObjFolder.map(v => (
	 <option value={v.id} selected={selected_file_folderid == v.id}>{v.name}</option>
    ));
	let subFolderList = this.state.ObjSubFolder.map(v => (
	 <option value={v.id} selected={selected_sub_file_folderid == v.id}>{v.name}</option>
    ));
	//let file_attachment_cache_on_app;
	//let file_attachment_cache_on_app_val = this.state.ObjFile.cache_on_app;
	
	
	if (localStorage.getItem('session')) {
    return (
        <div className="bodyscroll">
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div className="navbar-collapse collapse">
        <ul className="nav navbar-nav navbar-left">
          <li><a  className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul  className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
            <li className=""><a data-original-title="" title="">Edit Profile</a></li>
            <li><a onClick= {this.logout} rel="nofollow" data-original-title="" title="">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
          <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » <a onClick={this.redirectAttachment}>Supporting Files</a>
          </div>
        </div>
        <div  className="row">
          <div  className="col-xs-12">
          <h2>Edit Supporting File</h2>
  
  <form acceptCharset="UTF-8"
  onSubmit= {this.fileSubmit.bind(this)}
   className="new_file_folder"
  id="new_file_folder">
    <p><strong>File name:</strong>&nbsp;{this.state.ObjFile.attachment}</p>
    <div  className="form-group">
      <label htmlFor="file_attachment_file_folder_id">Select Folder</label>&nbsp;
      <select
	  id="file_attachment_file_folder_id"
	  name="file_attachment_file_folder_id"
	  ref="file_folderid"
	  className="form-control"
	  onChange={this.handleChangeName.bind(this, "file_attachment_file_folder_id")}
	  >
	  <option value="">None</option>
	  {folderList}
	  </select>
	  &nbsp;
	  <label htmlFor="file_attachment_file_folder_id">Select Subfolder</label>&nbsp;
	  <select
	  id="file_attachment_sub_folder_id"
	  name="file_attachment_sub_folder_id"
	  ref="file_subfolderid"
	  className="form-control"
	  onChange={this.handleChangeSubFolderName.bind(this, "file_attachment_sub_folder_id")}
	  >
	  <option value="">None</option>
	  {subFolderList}
	  </select>
    </div>
    
    <div className="form-group">
      <input className="btn btn-success" name="commit" type="submit" value="Save" />&nbsp;
	  <a className="btn btn-default" onClick={this.redirectAttachment}>Cancel</a>
    </div>
    
  </form>
          </div>
        </div>
      </div>
    </div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default EditFile;