import React, { Component } from 'react';
import './email_popup_style.css';
import axios from 'axios';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import $ from 'jquery';
import Base64 from 'base-64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class EmailPopup extends Component{
	constructor(props){
        super(props);
        this.state = {
        email_to: '',
        fields: {},
		pdf_template: {},
		ObjPDF: [],
		loader_class: '',
		ObjOrganization: '',
        error: {}
        }
     }
  handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
	  let collection_id = localStorage.getItem("collectionid");
	  let email_to = document.getElementById('email_to').value;
	  let pdf_check = document.getElementById('emailpdf').checked;
	 
     //action 
     fields["action"]="sendEmailPDF";
	 fields["organization_id"]=organization_id;
	 fields["collection_id"]=collection_id;
      //organization_name
      if(email_to==''){
         formIsValid = false;
         errors["email_to"] = "Enter Recepinet email id!";
      }else if(pdf_check==false)
	  {
	   formIsValid = false;
       errors["email_to"] = "Select PDF for Attachment!";	  
	  }else{
        fields["email_to"]=email_to;
	  }
     this.setState({errors: errors});
     return formIsValid;
	  
    }	 
  handleChangeName(field, e){
      let fields = this.state.fields;
      fields[field] = e.target.value;       
      this.setState({fields});
    }
	sendEmailPDF(e){
	 e.preventDefault();
	 if(this.handleValidation()){
	 console.log(this.state.fields);
	 this.sendEmailPDFApi();	 
	 }else{
	 toast.error('Mandatory field Missing!', {
			  position: toast.POSITION.TOP_CENTER,
			  autoClose: 4000
			});
	 }
	}
	
	
	
	
	handleChange({target}){
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
	  let collectionid = localStorage.getItem("collectionid");	
          if (target.checked){	  
		   window.open('https://safeguardapi.safeguardmerp.com/tcpdf/examples/collection_email_pdf.php?collectionid='+collectionid+'&organizationid='+organization_id+'', '_blank');
		   window.focus();
          } else {
		   alert('false'); 	  
          }
      }
	
 openPDF()
 {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid=session_array.organization_id;
	  let organization_name = localStorage.getItem("organization_name");
	  let collectionid = localStorage.getItem("collectionid");
	  let pdf_template = this.state.pdf_template;
      let ObjPDF = this.state.ObjPDF;
	  pdf_template["organization_id"]=organizationid;
	  pdf_template["organization_name"]=organization_name;
	  pdf_template["collectionid"]=collectionid;
	  pdf_template["action"]='showPDF1';
	  
	  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.pdf_template)
     })
      .then((response) => response.json())
      .then((response) => {
			 const status=response.status;
			 const filename=response.filename;
			 
			 if(status === "true")
             {
				 //window.location.href = 'https://safeguardapi.safeguardmerp.com/download/'+filename;
				 window.location.href = 'https://safeguardmerp.com/pdf_download/'+filename;
			 }
      });
 }
	
    sendEmailPDFApi()
    {
	  let collection_id = localStorage.getItem("collectionid");
	  this.setState({
              loader_class: 'loader'
          })
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
		   const count=response.count;
           if(status === "true")
           {
		      this.setState({
              loader_class: ''
          })
              toast.success('Email has successfully sent with attachments', {
			  position: toast.POSITION.TOP_CENTER,
			  autoClose: 4000
			});
			  window.location.reload();
           }
           else{
			  toast.error('Something went wrong, please try again', {
			  position: toast.POSITION.TOP_CENTER,
			  autoClose: 4000
			});
           }
      })
	  .catch((err)=>{
			  console.log(JSON.stringify(err));
			   toast.error(err, {
			  position: toast.POSITION.TOP_CENTER,
			  autoClose: 4000
			});
			 });
    }	 
	
  render() {
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
	  let collectionid = localStorage.getItem("collectionid");
    return (
      <div className='popup'>
	  <ToastContainer closeButton={false} />
        <div className='popup_inner'>
		<div class="modal-header">
          <h4>Email Quality PDF</h4>
		  </div>
		        <div class="modal-body">
				<form class="new_variable"
				 id="new_variable"
                 acceptCharset="UTF-8"
				 onSubmit= {this.sendEmailPDF.bind(this)}>
				<div></div>
				<div class="row">
				<span class="required">*</span><label> Recipient</label>
				<input 
				class="form-control"
				id="email_to"
				name="email_to"
				onChange={this.handleChangeName.bind(this, "email_to")}
                value={this.state.fields["email_to"]}
				type="text" />
				</div>
				<br/>
				<div class="row">
			  <span class="required">*</span>	
			  <input
			  type="checkbox"
			  id="emailpdf"
			  name="emailpdf" />&nbsp;
			  <label>Attach PDF</label>	
				<a class="email_pdf_link pdf_link" onClick={this.openPDF.bind(this)}>View Attachment</a>
				</div>
				<div class="row">
			    <input class="btn btn-primary" name="commit" type="submit" value="Send Email" /> <div class={this.state.loader_class}></div>
				</div>
			    </form>
                </div>
				<div class="modal-footer">
				<button onClick={this.props.closePopup} class="btn btn-default">close</button>
				</div>
        </div>
      </div>
    );
  }
}
export default EmailPopup;