import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization'
import Home from './Home';

import Login from './Login';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import alasql from 'alasql';
import DocView from './DocView';
import $ from 'jquery';
import Base64 from 'base-64';
import FileViewer from 'react-file-viewer';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
//import DocViewer from "react-doc-viewer";
class Doc extends Component {
    constructor(props){
        super(props);
        this.state = {
          orgid:null,
          ObjFolder: [],
		  ObjSubFolder: [],
          ObjLinks:[],
		  ObjSubFolderLinks: [],
          objfolderloading:false,
		  ObjCollection : [],
		  collection_template: {},
          openfolder:false,
		  openSubfolder:false,
          openfile:false,
		  openSubFolderFile:false,
		  showDocViewer: false,
		  customValue: '',
		  foldername: '',
		  subfoldername: '',
		  ObjOrganization: '',
		  filepath : '',
		  filetype : '',
		  filefolderid : '',
		  filesubfolderid : ''
        }
        this.Gotoorg=this.Gotoorg.bind(this);
        this.GetFolderList=this.GetFolderList.bind(this);
        this.GotoHome=this.GotoHome.bind(this);
		this.GotoBack=this.GotoBack.bind(this);
        this.Gotoorg=this.Gotoorg.bind(this);
        this.GotoHelp=this.GotoHelp.bind(this);
        this.GotoDoc=this.GotoDoc.bind(this);
        this.GotoProfile=this.GotoProfile.bind(this);
		this.logout=this.logout.bind(this);
		this.handleOverViewClose = this.handleOverViewClose.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.fileSubFolder = this.fileSubFolder.bind(this);
		this.fileSubFolderView = this.fileSubFolderView.bind(this);
     }

Gotoorg(){
  this.props.history.push('/Organization');
  }

  GotoBack()
  {
	  this.props.history.push('/Collection');
  }
  GotoHome(){
    this.props.history.push('/Collection');
  }
GotoHelp(){
  this.props.history.push('/help');
}

GotoDoc(){
  this.props.history.push('/Doc');
}

GotoProfile(){
  this.props.history.push('/Profile');

}

  logout() {
	  confirmAlert({
      title: 'Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => localStorage.clear(window.location.href = '/')
        },
        {
          label: 'No'
        }
      ]
    });
	}
	
	clearLocalStorage()
	  {
		localStorage.clear();
		window.location.href = '/';
	  }

 componentDidMount(){
 this.GetFolderList();
 this.getCollectionList();
 this.getOrganizationList();
 }
     getOrganizationList()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }
     GetFolderList(){
      let sessiondata= localStorage.getItem("safeguard");
      let sdata=JSON.parse(sessiondata);
            this.setState({
              ObjFolder: sdata.folders,
			  ObjSubFolder: sdata.folders.sub_folder_details,
              orgisloading:false,
              openfolder:true
          })
    }
    Gotoopenfile(e){
	  let query_value = '0';	
      let safeguard= localStorage.getItem("safeguard");
      let safeguarddata=JSON.parse(safeguard);
      let filedata=safeguarddata.links;
	  let folderdata=safeguarddata.folders;
      let res= alasql('SELECT * FROM ? where file_folder_id = ? and sub_folder_id = ?', [filedata,e,query_value]);
	  let res_folder = alasql('SELECT * FROM ? where id = ?', [folderdata,e]);
	  let folder_name = res_folder[0].name;
	  let subfolder_array = res_folder[0].sub_folder_details;
	  localStorage.setItem('subfolder_array', '');
	  localStorage.setItem('subfolder_array', JSON.stringify(subfolder_array));
      this.setState({
        ObjLinks:res,
        openfolder:false,
        openfile:true,
		foldername:folder_name,
		filefolderid : e
      })
    }
	
	fileSubFolder(folderid,subfolderid){
		
		let safeguard= localStorage.getItem("safeguard");
        let safeguarddata=JSON.parse(safeguard);
		let folderdata=safeguarddata.folders;
		let filedata=safeguarddata.links;
		let res=alasql('SELECT * FROM ? where file_folder_id = ? and sub_folder_id = ? and sub_folder_id > ?', [filedata,folderid,subfolderid,'0']);
		localStorage.setItem('subfolder_links', '');
		localStorage.setItem('subfolder_links', JSON.stringify(res));
		let res_folder = alasql('SELECT * FROM ? where id = ?', [folderdata,folderid]);
		let sub_folder_array = res_folder[0].sub_folder_details;
		let res_subfolder =  alasql('SELECT * FROM ? where id = ?', [sub_folder_array,subfolderid]);
		let sub_folder_name = res_subfolder[0].name;
		localStorage.setItem('subfoldername', '');
		localStorage.setItem('subfoldername', sub_folder_name);
       $(".folder_back").hide();
	   $(".subfolder_back").show();
	   $(".subfolder_link").show();
	   $(".subfolder_item").hide();
	   $(".folder_file_item").hide();
	   this.setState({
	   openSubFolderFile:true,
	   filesubfolderid : subfolderid
      })
    }
	
	fileSubFolderView(folderid,subfolderid){
		
		let safeguard= localStorage.getItem("safeguard");
        let safeguarddata=JSON.parse(safeguard);
		let folderdata=safeguarddata.folders;
		let filedata=safeguarddata.links;
		let res=alasql('SELECT * FROM ? where file_folder_id = ? and sub_folder_id = ? and sub_folder_id > ?', [filedata,folderid,subfolderid,'0']);
		localStorage.setItem('subfolder_links', '');
		localStorage.setItem('subfolder_links', JSON.stringify(res));
		let res_folder = alasql('SELECT * FROM ? where id = ?', [folderdata,folderid]);
		let sub_folder_array = res_folder[0].sub_folder_details;
		let res_subfolder =  alasql('SELECT * FROM ? where id = ?', [sub_folder_array,subfolderid]);
		let sub_folder_name = res_subfolder[0].name;
		localStorage.setItem('subfoldername', '');
		localStorage.setItem('subfoldername', sub_folder_name);
       $(".folder_back").hide();
	   $(".subfolder_back").show();
	   $(".subfolder_link").show();
	   $(".subfolder_item").hide();
	   $(".folder_file_item").hide();
	   this.setState({
	   openSubFolderFile:true,
	   openfolder:false,
	   openfile:false,
	   filesubfolderid : subfolderid,
	   showDocViewer: false
      })
    }
	
	
	
	
    docViewer(filepath,folderid)
	{
		//console.log(filepath);
	
		let myArray = filepath.split(/\.(?=[^\.]+$)/);
		let filetype = myArray[1];
		//console.log(filetype);
		filepath = Base64.encode(filepath);
		this.setState({
			showDocViewer: true,
			filepath : filepath,
			filetype : filetype
		});
		
		//this.props.history.push('/DocView?='+filepath);

		
	}
    CloseFolder(){
      this.setState({
        openfolder:true,
        openfile:false,
		filefolderid: ''
      })
    }
	CloseSubFolder(){
		localStorage.setItem('subfoldername', '');
		this.setState({
	   filesubfolderid : ''				 
      })
	   $(".folder_back").show();
	   $(".subfolder_back").hide();
	   $(".subfolder_link").hide();
	   $(".subfolder_item").show();
	   $(".folder_file_item").show();
	   
	   this.Gotoopenfile(this.state.filefolderid);
	   
    
    }
  toggle(id) {
    this.setState({
          customValue: id
      })
  }

  getCollectionList()
  {
	  let safeguard= localStorage.getItem("safeguard");
      let safeguard_array=JSON.parse(safeguard);
	  let collection_array = safeguard_array.collections;
	  if(collection_array!='')
	  {
	  let first_collection_id = collection_array[0].id;
	  this.setState({
          ObjCollection: safeguard_array.collections,
		  customValue: first_collection_id
      })
	  }
  }
  handleOverViewClose() {
		this.setState({ showDocViewer: false, filesubfolderid: '' });
		this.Gotoopenfile(this.state.filefolderid)
	}
	handleClose(){
		this.setState({ showDocViewer: false, filesubfolderid: '', filefolderid: '' });
	}
  render() {
	  if (localStorage.getItem('session')) {
		  
		  if (this.state.showDocViewer) {
			  return (
					  <DocView
					  filepath={this.state.filepath}
					  filetype={this.state.filetype}
					  filefolderid={this.state.filefolderid}
					  filesubfolderid={this.state.filesubfolderid}
					  closeFlowChartView={this.handleOverViewClose}
					  closeFileSubFolderView={this.fileSubFolderView}
					  closeFileView={this.handleClose}
					  />
					  );
		  }
	
	let subfolder_file_block;   
    if (localStorage.getItem('subfolder_links')) {
		let subfolder_links = localStorage.getItem("subfolder_links");
	    let subfolder_links_array=JSON.parse(subfolder_links);
		subfolder_file_block = subfolder_links_array.map((lin,index) => {
	const filename = lin.name;
	const folderid = lin.file_folder_id;
	const filename_array = filename.split(".");
	const filename_array_count = filename_array.length;
	const file_extension = parseInt(filename_array_count) - 1;
	const extension = filename_array[file_extension];
	
  return <div key={index}><div className="file" key={lin.id}><a data-type={extension} onClick={()=>this.docViewer(lin.url,folderid)}>{lin.name}</a></div></div>
	  });
	}else{ 
		subfolder_file_block = '';
	}
	let subfoldername;
	if (localStorage.getItem('subfoldername')) {
	subfoldername = localStorage.getItem("subfoldername");
	}else{
		subfoldername = '';
	}
	let token = localStorage.getItem("session");
	let token_array=JSON.parse(token);
	let view_files = token_array.view_files;
	let user_level_2 = token_array.admin;
	let user_level_1 = token_array.organization_admin;
	let hide_profile = token_array.hide_profile;
	let hide_logout = token_array.hide_logout;
	let user_super_admin = token_array.super_admin;  
	let safeguard= localStorage.getItem("safeguard");
	let safeguard_array = JSON.parse(safeguard);
	let folders = safeguard_array.folders;
	let subfolder_block;
	if (localStorage.getItem('subfolder_array')) {
		let Subfolders = localStorage.getItem("subfolder_array");
	    let Subfolders_array = JSON.parse(Subfolders);
		subfolder_block = Subfolders_array.map((lin,index) => {
		return <div key={index}><div className="folder subfolder_item" key={lin.id} onClick={()=>this.fileSubFolder(lin.folder_id,lin.id)}><a>{lin.name}</a></div></div>											 
		});
	}else{
		subfolder_block = '';
	}
    if(this.state.orgisloading){
      return <p>Loading ...</p>;
    }
	
   let folder_file_block = this.state.ObjLinks.map((lin,index) => {
	const filename = lin.name;
	const folderid = lin.file_folder_id;
	const filename_array = filename.split(".");
	const filename_array_count = filename_array.length;
	const file_extension = parseInt(filename_array_count) - 1;
	const extension = filename_array[file_extension];
  
  return <div key={index}><div className="file folder_file_item" key={lin.id}><a data-type={extension} onClick={()=>this.docViewer(lin.url,folderid)}>{lin.name}</a></div></div>
	  });
   
   let supporting_material = this.state.ObjCollection.map((v, index) => {
	return (		
	<p key={index}>															 
<a data-collection-id={v.id} className={this.state.customValue==v.id? 'active accordion-head': 'accordion-head'} onClick={()=>this.toggle(v.id)}>{v.supporting_material_title}</a>
    <div data-collection-id={v.id} className="active">
	<div className={this.state.customValue==v.id? 'supporting_material_content': 'supporting_material_content hideobject'} id={v.id}>{ReactHtmlParser(v.supporting_material_content)}</div>
	
    </div>
	</p>
	)
	});
	
    return (
			
      <div id="ember361" className="ember-view ember-app bodyStyle">
      <div className="menu">
      <ul>
        <li onClick= {this.GotoHome} className="home"><a>h</a></li>
        <li className="back"><a>M</a></li>
        <li className="titlebar">{localStorage.getItem("organization_name")}</li>
        {hide_logout==0?<li className="logout" onClick= {this.logout}><a>o<span className="text">Logout</span></a></li>:''}
        {hide_profile==0?<li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>:''}
        <li className="docs"><a>d<span className="text">Docs</span></a></li>
        <li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
        {user_level_1==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
      </ul>
    </div>
    <div id="application">
      <div id="ember1291" className="ember-view document-view"><div id="ember1295" className="ember-view document-view">
      <div className="overlay-container">
        <a className="close" onClick= {this.GotoBack}>x</a>
        <div className="document_menu">
            {folders.length>0 && view_files==1 ?<a data-ember-action="1301" className="files_view">Files</a>:''}
            <a className="materials_view">Emergency<br></br>Phone Numbers</a>
        </div>
		
        <div className="support_materials">
	   
		{folders.length>0 && view_files==1?
            <div className="files">
           {this.state.openfolder ?
           <div>
            { this.state.ObjFolder.map((fol) =>							
				  { return fol.type=='Folder' ? <div className="folder" key={fol.id} onClick={()=>this.Gotoopenfile(fol.id)}><a>{fol.name}</a></div> : <div className="file" key={fol.id}><a data-type={fol.extension} onClick={()=>this.docViewer(fol.filepath,fol.id)}>{fol.name}</a></div>}
            )
			}
			
             </div>
			 
              :''}
			  
           {this.state.openfile ?
           <div>
		   <div className="back folder_back" onClick={()=>this.CloseFolder()}><a>Close {this.state.foldername}</a></div>
           <div>{subfolder_block}{folder_file_block}</div>
           </div>
          :''}
		  
		  {this.state.openSubFolderFile && this.state.filesubfolderid!='' ?
           <div>
		   <div className="back subfolder_back" onClick={()=>this.CloseSubFolder()}><a>Close {subfoldername}</a></div>
           <div className="subfolder_link">{subfolder_file_block}</div>
           </div>
          :''}
		  
            </div>
			: ''}
			
            <div className="materials">
            <div id="accordion">
			{supporting_material}
			</div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
   
    
    </div>
    
    </div>
    );
  
	  }else{
		  window.location.href = '/';
	  }
  }
}

export default Doc;
